import React from "react";
import Layout from "../layout/Layout";
import service1 from "../assets/servicePageImages/service1.png";
import service2 from "../assets/servicePageImages/service2.png";
import service3 from "../assets/servicePageImages/service3.png";
import service4 from "../assets/servicePageImages/service4.png";
import service5 from "../assets/servicePageImages/service5.png";
import { Box } from "@mui/material";
import SuccessSide from "../components/success/SuccessSide";
import DevelopmentServices from "../components/services/DevelopmentServices";
import ServiceVector2 from "../components/services/ServiceVector2";
import TalkWithUs from "../components/talk/TalkWithUs";
import ServiceVector from "../components/services/ServiceVector";
import WebAndAppMainPage from "../components/main/WebAndAppMainPage";
import WorkPortfolio from "../components/work/WorkPortfolio";
export default function AppDevelopment() {
  return (
    <Layout>
      <WebAndAppMainPage
        PageTitle="App Development"
        pageInfo="Rich experiences on small screens. Hactric creates apps for enterprises, businesses and consumers with better operational efficiency and engagement. Delivering excellence Hybrid App Development Services."
        image1={service1}
        image2={service5}
        image3={service2}
        image4={service4}
        image5={service3}
        btnLink="/hactric/addApp"
        btnName="GET STARTED"
      />

      <Box
        sx={{
          zIndex: "-1",
          position: "relative",
          top: { xs: "0", sm: "0", md: "-117px" },
        }}
      >
        <ServiceVector />
      </Box>
      <Box sx={{ mt: { xs: "200px", sm: "100px", md: "-260px" } }}>
        <SuccessSide
          SuccessTitle="Successful apps"
          Successinfo="We apply an agile development process & craft a human-centric design that reflects your brand message."
        />
      </Box>
      <ServiceVector2 />

      <DevelopmentServices />
      <ServiceVector />
      {/* <WorkPortfolio /> */}
      <div className="talkWithus">
        <div className="AppDevelopmentmargin">
          <TalkWithUs />
        </div>
      </div>
    </Layout>
  );
}
