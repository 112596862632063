import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import "../../styles/home.scss";
import Typography from "@mui/material/Typography";
export default function TechImg({
  altName,
  languageImg,
  languageName,
  isBold,
}) {
  return (
    <>
      {isBold ? (
        <Card
          sx={{
            height: "170px !important",
            width: "90%",
            marginTop: "1rem",
            fontFamily: "Poppins sans-serif",
            borderRadius: "40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <CardMedia className={"Imgcover"}>
            <img src={languageImg} alt={altName} />
          </CardMedia>
          <CardContent>
            <Typography
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "15px",
                  md: "1rem",
                  lg: "1rem",
                  xl: "1rem",
                },
                textAlign: "center",
                // padding: "1rem",
              }}
            >
              {languageName}
            </Typography>
          </CardContent>
        </Card>
      ) : (
        <Card
          sx={{
            height: "170px !important",
            width: "90%",
            marginTop: "1rem",
            borderRadius: "40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            fontWeight: "700px",
          }}
        >
          <CardMedia className={"Imgcover"}>
            <img src={languageImg} alt={altName} />
          </CardMedia>
          <CardContent>
            <Typography
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "15px",
                  md: "1rem",
                  lg: "1rem",
                  xl: "1rem",
                },
                fontWeight: "600",
                fontFamily: "Poppins",
                textAlign: "center",
              }}
            >
              {languageName}
            </Typography>
          </CardContent>
        </Card>
      )}
    </>
  );
}
