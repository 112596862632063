import React from "react";
import AboutUsMask from "../../assets/aboutUS/AboutUsMask.png";
import "../../styles/aboutUs.css";
import { Box, Grid, Paper } from "@mui/material";
import cust1 from "../../assets/aboutUS/cust1.png";
import cust2 from "../../assets/aboutUS/cust2.png";
import cust3 from "../../assets/aboutUS/cust3.png";
import stars from "../../assets/aboutUS/stars.png";
import Mask2 from "../mask/Mask2";
export default function CustomerConnect() {
  return (
    <>
      <div className={`${"ConnectCustomerMask"} `}>
        <img src={AboutUsMask} alt="AboutUsMaskAvatar" />
      </div>
      <Box
        sx={{
          marginTop: { xs: "100px", sm: "100px", md: "-150px" },
        }}
      >
        <Mask2
          TagName="Towards building a long lasting connect"
          xsGrid={12}
          smGrid={12}
          mdGrid={12}
          TagInfo=""
        />
      </Box>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
        className="Webcontainer"
      >
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <div className={"customerConnection"}>
            <img src={cust1} alt="aboutUsAvatar" />
            <p>Be the customer </p>
            <p>
              We understand your dreams, needs and challenges. A
              customer-centric company requires more than offering good customer
              service.
            </p>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <div className={"customerConnection"}>
            <img src={cust2} alt="aboutUsAvatar" />
            <p>We Design </p>
            <p>
              The power of design, combined with technology builds a digital
              experience that transforms lives.
            </p>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <div className={"customerConnection"}>
            <img src={cust3} alt="aboutUsAvatar" />
            <p>Building the idea</p>
            <p>
              Simple solutions for complicated challenges are the thriving force
              behind building your idea.
            </p>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <div className={"customerConnection"}>
            <img src={stars} alt="aboutUsAvatar" />
            <p>Simplify</p>
            <p>
              The simplicity of your idea speaks for itself. It sets back to the
              core of an experience that conveys a solution to customers in
              solving real problems.
            </p>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
