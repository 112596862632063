import React from "react";
import { NavLink } from "react-router-dom";
import "../styles/error.css";

const ErrorPage = () => {
  return (
    <div className="errorPage">
      <h4>404 page not found</h4>
      <NavLink to="/">Go Back</NavLink>
    </div>
  )
}

export default ErrorPage;
