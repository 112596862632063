import { Grid } from "@mui/material";
import React from "react";

const FooterEnd = () => {
  return (
    <div className="footer-end">
      <p>© {new Date().getFullYear()} Hactric. All rights reserved</p>
      <div className={"TermsAndCondition"}>
        <p>Terms & Conditions</p>
        <p>|</p>
        <p> Privacy Policy</p>
      </div>
    </div>
  );
};

export default FooterEnd;
