import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./styles/global.css";
import "./App.css";
import "./styles/marginHandler.css";
import Home from "./pages/Home";
import "react-multi-carousel/lib/styles.css";
import "react-toastify/dist/ReactToastify.css";
import AboutUs from "./pages/AboutUs";
import Careers from "./pages/Career";
import { Provider } from "react-redux";
import { store } from "./store";
import AppDevelopment from "./pages/AppDevelopment";
import WebDevelopmentMainPage from "./components/main/WebDevelopmentMainPage";
import ErrorPage from "./pages/ErrorPage";
import QuoteAutomation from "./pages/QuoteAutomation";
import AddApp from "./pages/AddApp";
import PortFolio from "./pages/Portfolio";
import PortfolioDetailMainPage from "./components/main/PortfolioDetailMainPage";
import JobDetail from "./pages/JobDetail";
import ApplicationStatus from "./pages/ApplicationStatus";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/hactric/aboutus",
      element: <AboutUs />,
    },
    {
      path: "/hactric/careers",
      element: <Careers />,
    },
    {
      path: "/hactric/appdevelopment",
      element: <AppDevelopment />,
    },
    {
      path: "/hactric/webdevelopment",
      element: <WebDevelopmentMainPage />,
    },
    {
      path: "/hactric/quoteauotamation",
      element: <QuoteAutomation />,
    },
    {
      path: "/hactric/addApp",
      element: <AddApp />,
    },
    {
      path: "/hactric/portfolio",
      element: <PortFolio />,
    },
    {
      path: "/hactric/portfolio/:projectDetail",
      element: <PortfolioDetailMainPage />,
    },
    {
      path: "/hactric/:jobId",
      element: <JobDetail />,
    },
    {
      path: "/hactric/ApplicationStatus",
      element: <ApplicationStatus />,
    },
    {
      path: "*",
      element: <ErrorPage />,
    },
  ]);

  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
}

export default App;
