import React from "react";
import { Grid } from "@mui/material";
import "../../styles/home.scss";
import "../../styles/technologyWithWork.scss";
import firebase from "../../assets/icons/firebase.png";
import js from "../../assets/icons/js.png";
import mongoDb from "../../assets/icons/mongoDb.png";
import react from "../../assets/icons/react.png";
import swift from "../../assets/icons/swift.png";
import html from "../../assets/icons/html.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import TechImg from "./TechImg";
export default function TechnologiesToWork() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5.5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3.5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2.5,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1.5,
    },
  };
  return (
    <>
      <Grid container className="Webcontainer">
        <Grid item xs={12} className={"techTitle"}>
          <p>Technologies we work with</p>
          <div className={"verticalBar"}></div>
        </Grid>
      </Grid>
      <Grid container className="Webcontainer displaytechtags ">
        <Grid item className="techCard" xs={6} sm={4} md={3} lg={2}>
          <TechImg
            languageImg={js}
            altName={"jsAvatar"}
            languageName={"Node.js"}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={2} className={"techCard"}>
          <TechImg
            languageImg={firebase}
            altName={"jsAvatar"}
            languageName={"Firebase"}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={2} className={"techCard"}>
          <TechImg
            languageImg={react}
            altName={"jsAvatar"}
            languageName={"React Native"}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={2} className={"techCard"}>
          <TechImg
            languageImg={mongoDb}
            altName={"jsAvatar"}
            languageName={"MongoDB"}
          />
        </Grid>
        <Grid item className={"techCard"} xs={6} sm={4} md={3} lg={2}>
          <TechImg
            languageImg={html}
            altName={"jsAvatar"}
            languageName={"HTML5"}
          />
        </Grid>
        <Grid item className={"techCard"} xs={6} sm={4} md={3} lg={2}>
          <TechImg
            languageImg={swift}
            altName={"jsAvatar"}
            languageName={"Swift"}
          />
        </Grid>
      </Grid>
      <div className="displaytechscrollView">
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlaySpeed={1000}
          autoPlay={false}
          keyBoardControl={true}
          containerClass="carousel-container displaytechimages"
          removeArrowOnDeviceType={[
            "tablet",
            "mobile",
            "desktop",
            "superLargeDesktop",
          ]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          <div className={"Singletechimg"}>
            <TechImg
              languageImg={js}
              altName={"jsAvatar"}
              languageName={"Node.js"}
            />
          </div>
          <div className={"Singletechimg"}>
            <TechImg
              languageImg={firebase}
              altName={"jsAvatar"}
              languageName={"Firebase"}
            />
          </div>
          <div className={"Singletechimg"}>
            <TechImg
              languageImg={react}
              altName={"jsAvatar"}
              languageName={"React Native"}
            />
          </div>
          <div className={"Singletechimg"}>
            <TechImg
              languageImg={mongoDb}
              altName={"jsAvatar"}
              languageName={"MongoDB"}
            />
          </div>

          <div className={"Singletechimg"}>
            <TechImg
              languageImg={html}
              altName={"jsAvatar"}
              languageName={"HTML5"}
            />
          </div>
          <div className={"Singletechimg"}>
            <TechImg
              languageImg={swift}
              altName={"jsAvatar"}
              languageName={"Swift"}
            />
          </div>
        </Carousel>
      </div>
    </>
  );
}
