import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  Fab,
  Button,
  Paper,
  CircularProgress,
} from "@mui/material";
import "../../styles/jobDetail.css";
import PropTypes from "prop-types";
import ImageVector from "../../assets/ImageVector.png";
import { ToastContainer, toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import api from "../../api/request";
import { getErrorMessage } from "../../helper/helper";
export default function JobViewForm({ jobId, name }) {
  const [isHuman, setIsHuman] = useState(false);
  const [windowSize, setWindowSize] = useState(
    typeof window === "undefined" ? 0 : window.innerWidth
  );
  const navigate = useNavigate();
  const [state, setState] = useState({
    fullName: "",
    email: "",
    phone: "",
    position: "",
  });

  const handleChange = (value) => {
    if (value) {
      setIsHuman(true);
    } else {
      setIsHuman(false);
    }
  };
  const trackingNumber = Date.now().toString();

  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [resume, setResume] = useState("");
  const uploadCv = async (doc) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${doc._boundary}`,
        },
      };
      setLoading(true);
      let resp = await api?.post("/api/image", doc);
      setLoading(false);
      setResume(resp?.data?.data[0]?.imgUrl);
      toast.success("File Upload");
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message || error);
    }
  };
  const uploadFileAndImages = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData?.append("files", file);
    await uploadCv(formData);
  };

  const handleform = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value.trim(),
    }));
  };
  const handleTheForm = async () => {
    try {
      if (!state?.fullName) {
        toast.error("Full Name is Required");
        return;
      } else if (
        !state?.email ||
        !state?.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
      ) {
        toast.error("Email is Required");
        return;
      } else if (!state?.phone) {
        toast.error("Phone Number is Required");
        return;
      } else if (!resume) {
        toast.error("Resume is Required");
        return;
      } else {
        const body = {
          FullName: state?.fullName,
          Email: state?.email,
          PhoneNo: state?.phone,
          Image: resume,
          jobId: jobId,
          Position: name,
          trackingNumber: trackingNumber,
          status: [
            { title: "Application Status", status: "Passed", date: new Date() },
            {
              title: "Shortlist Candidate",
              status: "Pending",
              date: new Date(),
            },
            {
              title: "HR Screening",
              status: "Pending",
              date: new Date(),
            },
            {
              title: "Final interview",
              status: "Pending",
              date: new Date(),
            },
            { title: "Hired", status: "Pending", date: new Date() },
          ],
        };
        setFormLoading(true);
        const resp = await api.post("/api/candidate/candidateRoute", body);
        setFormLoading(false);
        toast.success(resp?.data?.message);
        navigate("/hactric/ApplicationRecieved");
      }
    } catch (error) {
      getErrorMessage(error);
    }
  };

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth);
  };
  useEffect(() => {
    if (typeof window === "undefined") return;

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  return (
    <Grid
      container
      sx={{
        paddingLeft: "2rem",
        background: "#FFFFFF",
        borderRadius: "30px",
        width: "100%",
      }}
    >
      <Grid item xs={12} sm={12}>
        <p className={"JobTitle"}>Apply Now</p>
        <div className={"applyBottomBar"}></div>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography className={"formTitle"}>Full Name*</Typography>

        <TextField
          id="outlined-basic"
          label="Enter your full name"
          variant="outlined"
          onChange={handleform}
          name="fullName"
        />
      </Grid>
      <Grid item xs={12} sm={12} className={"formInput"}>
        <Typography className={"formTitle"}>Email*</Typography>
        <TextField
          id="outlined-basic"
          label="Enter your Email"
          variant="outlined"
          onChange={handleform}
          name="email"
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography className={"formTitle"}>Phone No*</Typography>
        <TextField
          id="outlined-basic"
          label="Enter your phone number*"
          variant="outlined"
          onChange={handleform}
          name="phone"
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography className={"formTitle"}>Position*</Typography>
        <p
          style={{
            fontSize: "1rem",
            fontWeight: "600",
          }}
        >
          {name}
        </p>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography className={"formTitle"}>Attach your resume*</Typography>
        <Paper
          elevation={3}
          style={{
            boxSizing: "border-box",
            width: "90%",
            padding: "0.5rem",
            // position: "absolute",
            borderRadius: "6px",
          }}
        >
          <label htmlFor="upload-photo">
            <input
              style={{
                display: "none",
              }}
              id="upload-photo"
              type="file"
              onChange={uploadFileAndImages}
            />
            <Button component="span" className={"ImageBtn"}>
              <img src={ImageVector} alt="VectorAvatar" />
              <p>Attach your resume </p>
            </Button>{" "}
          </label>
          <br />

          {loading ? <CircularProgress size={10} color="primary" /> : ""}
        </Paper>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          margin: "1rem 0",
        }}
      >
        {windowSize >= 450 ? (
          <ReCAPTCHA
            size="normal"
            // size="compact"
            sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}
            onChange={handleChange}
          />
        ) : (
          <ReCAPTCHA
            size="compact"
            // size="compact"
            sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}
            onChange={handleChange}
          />
        )}
        {/* {windowSize >= 450 ? "abdullah" : "hamza"} */}
      </Grid>
      <Grid item xs={12} className={"ApplyNow"}>
        <Button onClick={handleTheForm} disabled={!isHuman}>
          Apply Now
        </Button>
        {formLoading ? <CircularProgress size={20} /> : ""}
      </Grid>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Grid>
  );
}
JobViewForm.propTypes = {
  jobId: PropTypes.string,
  name: PropTypes.string,
};
