import React from "react";
import "../styles/carrier.css";
import colon from "../assets/CareersPage/colon.png";
import hatricPerson from "../assets/CareersPage/hatricPerson.png";
import developerVoice from "../assets/CareersPage/developerVoice.png";
import Arrow3 from "../assets/CareersPage/Arrow3.png";
import Carousel from "react-multi-carousel";
export default function HearFromHactricEmployees() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  return (
    <>
      <div className={`Webcontainer ${"EmployeeContainer"}`}>
        <div className={"hearFromPerson"}>
          <img src={colon} alt="colonAvatar" />
          <div className={"Colonmask"}></div>
          <p>
            "Hactric's work environment promotes employee safety, growth and
            goal attainment, comfortable physical conditions in which we perform
            our job. These environments are integral to a successful workforce
            as they encourage employees to perform to their highest ability."
          </p>
          <div className={"developerVoice"}>
            <img src={developerVoice} alt="developerAvatar" />
            <div className={"developerVoiceInfo"}>
              <p>John Doe</p>
              <p>Front-end Developer</p>
            </div>
            <img src={Arrow3} alt="ArrowAvatar" />
          </div>
        </div>
        <div>
          <div className={"hatricpersoncontainer1"}>
            <img src={hatricPerson} alt="hatricPersonAvatar" />
            <img src={hatricPerson} alt="hatricPersonAvatar" />
            <img src={hatricPerson} alt="hatricPersonAvatar" />
          </div>
          <div className={"hatricpersoncontainer2"}>
            <img src={hatricPerson} alt="hatricPersonAvatar" />
            <img src={hatricPerson} alt="hatricPersonAvatar" />
            <img src={hatricPerson} alt="hatricPersonAvatar" />
          </div>
        </div>
      </div>
      <div className={"hactricPersonScroll"}>
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlaySpeed={1000}
          autoPlay={true}
          keyBoardControl={true}
          containerClass="carousel-container"
          removeArrowOnDeviceType={[
            "tablet",
            "mobile",
            "desktop",
            "superLargeDesktop",
          ]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          <img src={hatricPerson} alt="hatricPersonAvatar" />
          <img src={hatricPerson} alt="hatricPersonAvatar" />
          <img src={hatricPerson} alt="hatricPersonAvatar" />
          <img src={hatricPerson} alt="hatricPersonAvatar" />
          <img src={hatricPerson} alt="hatricPersonAvatar" />
          <img src={hatricPerson} alt="hatricPersonAvatar" />
          <img src={hatricPerson} alt="hatricPersonAvatar" />
          <img src={hatricPerson} alt="hatricPersonAvatar" />
          <img src={hatricPerson} alt="hatricPersonAvatar" />
        </Carousel>
      </div>
    </>
  );
}
