import React from "react";
import { Grid } from "@mui/material";
import "../../styles/home.css";
import patnerShipVector from "../../assets/patnerImage/patnerShipVector.png";
import homePatnerShip from "../../assets/patnerImage/homePatnerShip.png";
import tag1 from "../../assets/patnerImage/Tag1.png";
import tag2 from "../../assets/patnerImage/Tag2.png";
import tag3 from "../../assets/patnerImage/Tag3.png";
import tag4 from "../../assets/patnerImage/Tag4.png";
import "react-multi-carousel/lib/styles.css";

import tag5 from "../../assets/patnerImage/Tag5.png";
import tag6 from "../../assets/patnerImage/Tag6.png";
import tag7 from "../../assets/patnerImage/Tag7.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ServiceVector from "../services/ServiceVector";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 400 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 400, min: 0 },
    items: 1.5,
  },
};

export default function PatnerShip() {
  return (
    <>
      <ServiceVector />
      <Grid
        container
        sx={{
          marginTop: { xs: "-100px", sm: "-100px", md: "-300px" },
        }}
        className="Webcontainer"
      >
        <Grid item xs={12} sm={12} md={6}>
          <p className={"ServiceTitle componentTitle"}>Our Partnerships</p>
          <div className={"partnerbar"}></div>
          <p className={"TagInfo"}>
            We are honored to have these amazing names with us on board
          </p>
          <div className={"patnerShipVector"}>
            <img src={patnerShipVector} alt="patnerShipVector" />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={"PatnerShipImage"}>
          <img src={homePatnerShip} alt="homePatnerShip" />
        </Grid>
      </Grid>
      <div className={""}>
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlaySpeed={2000}
          autoPlay={true}
          keyBoardControl={true}
          containerClass="carousel-container"
          removeArrowOnDeviceType={[
            "tablet",
            "mobile",
            "desktop",
            "superLargeDesktop",
          ]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px caurosalImgs"
        >
          <img src={tag1} alt="patnerShipVector" />

          <img src={tag4} alt="patnerShipVector" />

          <img src={tag3} alt="patnerShipVector" />

          <img src={tag2} alt="patnerShipVector" />

          <img src={tag5} alt="patnerShipVector" />

          <img src={tag6} alt="patnerShipVector" />

          <img src={tag7} alt="patnerShipVector" />
        </Carousel>
        ;
      </div>
    </>
  );
}
