import React from "react";
import ApplicationStatusMainPage from "../components/main/ApplicationStatusMainPage";
import MainPage from "../components/main/MainPage";
import Layout from "../layout/Layout";

export default function ApplicationStatus() {
  return (
    <Layout>
      <div>
        <ApplicationStatusMainPage />
      </div>
    </Layout>
  );
}
