import React from "react";
import Vector from "../../assets/servicePageImages/Vector.png";
import { Grid } from "@mui/material";
import "../../styles/services.css";
export default function ServiceVector() {
  return (
    <Grid container>
      <Grid item xs={12} className={"serviceMask"}>
        <img src={Vector} alt="Vector" />
      </Grid>
    </Grid>
  );
}
