import React from "react";
import { Box, Grid } from "@mui/material";
import DollarSign from "../../assets/CareersPage/DollarSign.png";
import time from "../../assets/CareersPage/time.png";
import "../../styles/carrier.css";
import Warranty from "../../assets/CareersPage/Warranty.png";
import CircleTrue from "../../assets/CareersPage/CircleTrue.png";
import chat from "../../assets/CareersPage/chat.png";
import person from "../../assets/CareersPage/person.png";
import Mask2 from "../mask/Mask2";
import JoinUsTags from "./JoinUsTags";
import ServiceVector2 from "../services/ServiceVector2";
export default function JoinUs() {
  return (
    <>
      <div className={"joinContainer"}>
        <Box
          sx={{
            zIndex: "-1",
            position: "relative",
            top: { xs: "0", sm: "0", md: "-117px" },
          }}
        >
          <ServiceVector2 />
        </Box>
      </div>
      <Box
        sx={{
          position: "relative",
          top: { xs: "0", sm: "-200px", md: "-510px !important" },
        }}
      >
        <Mask2
          TagName="Why Should You Join Us"
          xsGrid={12}
          smGrid={12}
          mdGrid={12}
          TagInfo=""
        />

        <Grid container className="container">
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
              mb: { xl: "2rem", sm: "2rem", md: "2rem" },
            }}
          >
            <JoinUsTags
              JoinUsTagImage={DollarSign}
              JoinUsTitle="Competitive Salary"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
              mb: { xl: "2rem", sm: "2rem", md: "2rem" },
            }}
          >
            <JoinUsTags
              JoinUsTagImage={Warranty}
              JoinUsTitle="Rewards &
            Benefits"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
              mb: { xl: "2rem", sm: "2rem", md: "2rem" },
            }}
          >
            <JoinUsTags JoinUsTagImage={time} JoinUsTitle="Flexible Timings" />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
              mb: { xl: "2rem", sm: "2rem", md: "2rem" },
            }}
          >
            <JoinUsTags
              JoinUsTagImage={person}
              JoinUsTitle="Annual Increment"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
              mb: { xl: "2rem", sm: "2rem", md: "2rem" },
            }}
          >
            <JoinUsTags
              JoinUsTagImage={chat}
              JoinUsTitle="Transparent Communication"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
              mb: { xl: "2rem", sm: "2rem", md: "2rem" },
            }}
          >
            <JoinUsTags JoinUsTagImage={CircleTrue} JoinUsTitle="Paid Leaves" />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
