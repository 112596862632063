import React from "react";
import "../../styles/webButton.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function WebButton({ LinkURl, LinkTitle, ArrowStatus }) {
  return (
    <div className={"WebButton"}>
      <a href={LinkURl}>
        {LinkTitle}
        {ArrowStatus ? (
          <ArrowForwardIcon
            sx={{
              margin: "-0.1rem 0.5rem",
              fontSize: "large",
            }}
          />
        ) : (
          ""
        )}
      </a>
    </div>
  );
}
