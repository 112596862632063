import React from "react";
import { Grid } from "@mui/material";
import "../../styles/services.css";
import PropTypes from "prop-types";
export default function WebAndAppMainPage({
  PageTitle,
  image1,
  image2,
  image3,
  image4,
  image5,
  btnLink,
  btnName,
  pageInfo,
}) {
  return (
    <Grid container className="Webcontainer" sx={{ paddingTop: "7rem" }}>
      <Grid item xs={12} sm={6} md={6} className={"image1"}>
        <img src={image1} alt="serviceAvatar" />
      </Grid>
      <Grid item xs={12} sm={6} md={6} className={"image2"}>
        <img src={image2} alt="serviceAvatar" />
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={"serviceTitle"}>
        <p>{PageTitle}</p>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={"pageInfo"}>
        <p>{pageInfo}</p>
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={"image3"}>
        <img src={image3} alt="serviceAvatar" />
      </Grid>

      <Grid item xs={12} sm={12} md={6} className={"image4"}>
        <img src={image4} alt="serviceAvatar" />
      </Grid>

      <Grid item xs={12} sm={12} className={"serviceBtn"}>
        <a href={btnLink}>{btnName}</a>
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={"image5"}>
        <img src={image5} alt="serviceAvatar" />
      </Grid>
    </Grid>
  );
}

WebAndAppMainPage.propTypes = {
  PageTitle: PropTypes.string,

  pageInfo: PropTypes.string,
  btnLink: PropTypes.string,
  btnName: PropTypes.string,
  // image1:
  // image2,
  // image3,
  // image4,
  // image5,
};
