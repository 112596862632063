import React from "react";
import { Button, Grid, Card } from "@mui/material";
import "../../styles/home.css";
export default function TalkWithUs() {
  return (
    <Card
      className={`Webcontainer ${"talkwithCard"}`}
      sx={{
        marginBottom: { xs: "-200px", sm: "-100px", md: "-100px"},
      }}
    >
      <Grid container>
        <Grid item xs={8} sm={8} md={8} className={"TalkWithUs"}>
          <p className={"TalkHeader"}>We are excited to talk to you</p>
          <p className={"TalkSubPara"}>
            Got a great project idea? Or want to transform your business?
          </p>
        </Grid>
        <Grid item xs={4} sm={4} md={4} className={"talkBtn"}>
          <a href="/hactric/addApp">
            <Button className={"getStared"}>GET STARTED</Button>
          </a>
        </Grid>
      </Grid>
    </Card>
  );
}
