import React, { useEffect, useState } from "react";
import { Grid, Button, Box } from "@mui/material";
import PropTypes from "prop-types";
import "../../styles/carrier.css";
import CareerArrow from "../../assets/CareersPage/CareerArrow.png";
import { useDispatch, useSelector } from "react-redux";
import ServiceVector from "../services/ServiceVector";
import Mask2 from "../mask/Mask2";
import JobPlacingContainer from "../jobs/JobPlacingContainer";
import { GetAllCurrentOpening } from "../../api/jobs";

export default function CurrentOpenings({ div, id }) {
  const dispatch = useDispatch();
  const [viewAll, setViewAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const { CurrentJobs } = useSelector((state) => state?.CurrentJobs);
  const filterJobs = CurrentJobs.filter((e) => e?.status === "active");

  const ViewAllJobs = () => {
    setViewAll(true);
  };
  useEffect(() => {
    if (CurrentJobs?.length > 0) {
      return;
    } else {
      GetAllCurrentOpening(dispatch, setLoading);
    }
  }, []);
  return (
    <Box
      sx={{
        position: "relative",
        top: { xs: "0", sm: "-200px", md: "-450px" },
        marginBottom: "3rem",
      }}
    >
      <ServiceVector />
      <Box
        className="Webcontainer"
        sx={{
          marginTop: { xs: "0", sm: "0", md: "-348px" },
        }}
      >
        <Mask2
          TagName="Current Openings"
          xsGrid={12}
          smGrid={12}
          mdGrid={12}
          TagInfo=""
        />
        <img src={CareerArrow} alt="ArrowAvatar" />

        <Grid container ref={div} id={id} className="mt-5">
          {CurrentJobs.length === 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <p
                style={{
                  color: "white",
                  fontWeight: 600,
                  fontSize: "1.5rem",
                }}
              >
                No Jobs Avaliable
              </p>
            </div>
          ) : viewAll === false && filterJobs.length > 3 ? (
            filterJobs
              ?.slice(0, 3)
              ?.map((e, i) => (
                <JobPlacingContainer key={i} JobImage={e?.Image} data={e} />
              ))
          ) : (
            filterJobs?.map((e, i) => (
              <JobPlacingContainer key={i} JobImage={e?.Image} data={e} />
            ))
          )}
        </Grid>
        {viewAll === false && filterJobs.length > 3 ? (
          <Grid item xs={12} className={"ViewAllJobsBtn"}>
            <Button onClick={ViewAllJobs}>View All({filterJobs.length})</Button>
          </Grid>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
}
CurrentOpenings.propTypes = {
  div: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  id: PropTypes.string,
};
