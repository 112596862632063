const initialState = { portfolio: [] };

export default function PortfolioReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_ALL_PROJECT":
      return { ...state, portfolio: action.payload };

    default:
      return state;
  }
}
