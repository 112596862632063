import { getErrorMessage } from "../helper/helper";
import api from "./request";

export const GetAllCurrentOpening = async (dispatch, setLoading) => {
  try {
    // setLoading(true);
    const resp = await api.get("/api/Job/jobRoute");
    dispatch({
      type: "GET_CurrentOpening",
      payload: resp?.data?.data,
    });
    // setLoading(false);
    return;
  } catch (error) {
    getErrorMessage(error);
  }
};
export const handlerQuotes = async (dispatch) => {
  try {
    const resp = await api.get("/api/QuoteAutomation/QuoteAutomationRoute");
    dispatch({
      type: "GET_QUOTE_AUTOMATION",
      payload: resp?.data?.resp,
    });
  } catch (error) {
    getErrorMessage(error);
  }
};
export const fetchInitialData = async (dispatch, loading, setLoading, setProjectsDetail) => {
  try {
    setLoading(true);
    const resp = await api.get("/api/portfolio/portfolioRoute");
    setLoading(true);
    dispatch({
      type: "GET_ALL_PROJECT",
      payload: resp?.data?.resp,
    });

    setProjectsDetail(resp.data.resp);
  } catch (error) {
    getErrorMessage(error);
  }
};
