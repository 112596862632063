import React from "react";
import PortfolioMainPage from "../components/main/PortfolioMainPage";
import { projects } from "../helper/helper";
import Layout from "../layout/Layout";
export default function PortFolio() {
  return (
    <Layout>
      <PortfolioMainPage projects={projects} />
    </Layout>
  );
}
