import React from "react";
import "../styles/appStyle.css";
import Layout from "../layout/Layout";
import AppForm from "../components/forms/AppForm";
export default function AddApp() {
  return (
    <Layout>
      <div className={"AddAppContainer"}>
        <AppForm />
      </div>
    </Layout>
  );
}
