import React from "react";
import HomeMask from "../../assets/HomeMask.png";
import "../../styles/home.css";
import { Grid } from "@mui/material";
export default function Mask({ TagName, xsGrid, smGrid, mdGrid, TagInfo }) {
  return (
    <Grid container>
      {/* <Grid item xs={12} className={"homeMask"}>
        <img src={HomeMask} alt="MaskAvatar" />
      </Grid> */}
      <Grid
        item
        xs={xsGrid}
        sm={smGrid}
        md={mdGrid}
        className={"ServiceContainer"}
      >
        <p className={"ServiceTitle"}>{TagName}</p>
        <div className={"verticalBar"}></div>
        {TagInfo && <p className={"TagInfo"}>{TagInfo}</p>}
      </Grid>
    </Grid>
  );
}
