import React from "react";
import { Grid } from "@mui/material";
import "../../styles/carrier.css";

export default function TitleInfo({ title, info }) {
  return (
    <Grid container className={`Webcontainer`}>
      <Grid item xs={12} sm={12}>
        <p className={"componentPageTitle"}>{title}</p>
        <p className={"PageInfo"}>{info}</p>
      </Grid>
    </Grid>
  );
}
