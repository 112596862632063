import axios from "axios";
const api = axios.create({
  // baseURL:
  //   process.env.NODE_ENV == "production"
  //     ? process.env.NEXT_PUBLIC_Deploy_URL
  //     : process.env.NEXT_PUBLIC_LOCAL_URL,
  //baseURL: "http://localhost:3001",
  baseURL: "https://api.hactric.com"
  // baseURL: "http://ec2-15-206-178-2.ap-south-1.compute.amazonaws.com",
});
export default api;
