import { Grid } from "@mui/material";
import React from "react";
import "../../styles/technologyStack.css";
import PropTypes from "prop-types";
import StackTags from "../tags/StackTags";
export default function TechnologyStack({ filterProject }) {
  return (
    <Grid container className={`${"stackContainer"} Webcontainer`}>
      <Grid item xs={12} className={`${"stackHead"}`}>
        <p>Technology stack used in making this project successful</p>
      </Grid>
      {filterProject?.TechnologyUsed?.map((e, i) => (
        <Grid key={i} item xs={4} sm={4} md={2} className={"stackData"}>
          <StackTags
            StackImage={e?.img}
            StackThing={e?.title}
            StackName={e?.name}
          />
        </Grid>
      ))}
    </Grid>
  );
}
TechnologyStack.propTypes = {
  filterProject: PropTypes.object.isRequired,
};
