import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import "../../styles/portfolio.css";
import hImage from "../../assets/portfolio/hImage.png";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import WebPortfolio from "../portfolio/WebPortfolio";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function PortfolioMainPage({ projects }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid item xs={12}>
        <div className={"HeadImg"}>
          <img src={hImage} alt="porfolioAvatar" />
          <div className={"overlay"}></div>
        </div>
        <div className={"introWords"}>
          <p>
            We helped 100+ global companies to digitalize their business with
            our unmatchable tech solutions.
          </p>
        </div>
      </Grid>

      <Grid item className={`container ${"TabsHeader"}`}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {projects?.map((e, i) => (
              <Tab
                key={i}
                label={
                  e?.title === "Web_Development"
                    ? "Web Development"
                    : "App Development"
                }
                {...a11yProps(i)}
              />
            ))}
          </Tabs>
        </Box>
        {projects.map((e, i) => (
          <TabPanel key={i} value={value} index={i} className={"TabPanelcolor"}>
            <WebPortfolio
              projecttitle={projects[i].title}
              projectListing={projects[i].completed}
            />
          </TabPanel>
        ))}
      </Grid>
    </Grid>
  );
}
PortfolioMainPage.propTypes = {
  projects: PropTypes.array.isRequired,
};
