import React, { useRef } from "react";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import Layout from "../layout/Layout";
import TalkWithUs from "../components/talk/TalkWithUs";
import Mask2 from "../components/mask/Mask2";
import CurrentOpenings from "../components/openings/CurrentOpenings";
import MainPage from "../components/main/MainPage";
import JoinUs from "../components/join/JoinUs";
import HearFromHactricEmployees from "../components/HearFromHactric";
import CareerMainPage from "../components/main/CareerMainPage";
export default function Careers() {
  const myDivRef = useRef(null);

  return (
    <Layout>
      <CareerMainPage />
      <JoinUs />
      <CurrentOpenings div={myDivRef} id="myDivId" />
      <div style={{}}>
        <Mask2
          TagName="Hear From Hactric Employees"
          xsGrid={12}
          smGrid={12}
          mdGrid={12}
          TagInfo=""
        />
        <HearFromHactricEmployees />
        <div className="careerTlakWithUs">
          <TalkWithUs />
        </div>
      </div>
    </Layout>
  );
}
