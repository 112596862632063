import React from "react";
import "../../styles/technologyStack.css";
export default function StackTags({ StackImage, StackThing, StackName }) {
  return (
    <>
      <div className={`${"StackImage"}`}>
        {StackImage && (
          <img
            src={StackImage}
            alt="TechAvatar"
            width={100}
            height={100}
            style={{
              objectFit: "scale-down",
            }}
          />
        )}
      </div>
      <div>
        <p className={`${"specifyThing"}`}>{StackThing}</p>
        <p className={`${"specifyThingName"}`}>{StackName}</p>
      </div>
    </>
  );
}
