import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import "../../styles/appStyle.css";
import Message from "../../assets/FooterImage/Message.png";
import Mobile from "../../assets/FooterImage/Mobile.png";
import Location from "../../assets/FooterImage/Location.png";
import webLogo from "../../assets/webLogo.png";
import ReCAPTCHA from "react-google-recaptcha";
import { toast, ToastContainer } from "react-toastify";
import { getErrorMessage } from "../../helper/helper";
import { useNavigate } from "react-router-dom";
import api from "../../api/request";

export default function AppForm() {
  const [isHuman, setIsHuman] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useState(
    typeof window === "undefined" ? 0 : window.innerWidth
  );
  const [state, setState] = useState({
    Clientname: "",
    email: "",
    phoneNumber: "",
    budget: "",
    description: "",
  });
  const handleWindowResize = () => {
    setWindowSize(window.innerWidth);
  };
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,

      [name]: value,
    }));
  };
  const handleChange = (value) => {
  //   if (value) {
  //     setIsHuman(true);
  //   } else {
  //     setIsHuman(false);
  //   }
  };
  const formHandler = async () => {
    try {
      if (!state?.Clientname) {
        toast.error("Name is required");
        return;
      }
      if (
        !state?.email ||
        !state?.email?.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
      ) {
        toast.error("Email is required");
        return;
      }
      if (!state?.phoneNumber) {
        toast.error("Phone Number is required");
        return;
      }
      if (!state?.phoneNumber?.match(/^[0-9]+$/)) {
        toast.error("Phone Number is not Valid");
        return;
      }
      if (!state?.budget) {
        toast.error("Budget is required");
        return;
      }
      // if (!state?.budget?.match(/^[0-9]+$/)) {
      //   toast.error("Budget is must in Number");
      //   return;
      // }
      if (!state?.description) {
        toast.error("Description is required");
        return;
      }
      if (state?.description.length <= 100) {
        toast.error("Description must have 100 words");
        return;
      }

      const body = {
        Clientname: state?.Clientname,
        email: state?.email,
        phoneNumber: state?.phoneNumber,
        budget: state?.budget,
        description: state?.description,
      };
      setLoading(true);
      const resp = await api.post("/api/appform", body);
      setLoading(false);
      toast.success(resp.data.message);
      navigate("/hactric/AppThanksMessage");
    } catch (error) {
      setLoading(false);
      getErrorMessage(error);
    }
  };
  useEffect(() => {
    if (typeof window === "undefined") return;

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  return (
    <Paper elevation={3} className={"AppFormContainer"}>
      <Grid container>
        {/* <Grid item xs={12} sm={12} md={6}>
          <Grid container className={"FormContainer"}>
            <div>
              <div className={"addAppPageTitle"}>
                <p>Got a project in mind?</p>
              </div>
              <div className={"pageSubTitle"}>
                <p>We guarantee to get back to you within a business day.</p>
              </div>
            </div>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                label="Enter Name"
                name="Clientname"
                className={"FormInput"}
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                onChange={handleFormChange}
                label="Enter Email"
                name="email"
                className={"FormInput"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                onChange={handleFormChange}
                name="phoneNumber"
                label="Enter Phone number"
                className={"FormInput"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} className={"FormInput"}>
              <TextField
                onChange={handleFormChange}
                label="Enter Budget"
                name="budget"
              />
              <span className={"addWarning"}>Must Add Currency (Ex: 100$)</span>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                onChange={handleFormChange}
                id="outlined-multiline-static"
                label="Enter Project Description"
                multiline
                name="description"
                rows={4}
                className="FormInput"
                sx={{
                  width: "90%",
                }}
              />
            </Grid>
            {/* <div>
              {windowSize >= 450 ? (
                <ReCAPTCHA
                  size="normal"
                  // size="compact"
                  sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}
                  onChange={handleChange}
                />
              ) : (
                <ReCAPTCHA
                  size="compact"
                  // size="compact"
                  sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}
                  onChange={handleChange}
                />
              )}
            </div> */}
            {/* <Grid item xs={12} sm={12} md={12} className={"SendNow"}>
              <Button
                onClick={formHandler}
                disabled={!isHuman}
                className={!isHuman ? "diableclass" : "activeClass"}
              >
                Send Now
              </Button>
              <div>{loading ? <CircularProgress /> : ""}</div>
            </Grid> */}

            {/* <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            /> 
          </Grid>
        </Grid> */}
        <Grid item xs={12} sm={12} md={12} className={"ContactUsContainer"}>
          {/* <div className={"WebLogo"}>
            <img src={webLogo} alt="WebLogo" />
          </div> */}
          <div className={"ContactUsInfo"}>
            <p>You can reach us at</p>
            <div className={"MessageContainer"}>
              <div>
                <span>
                  <img src={Message} alt="MessageAvatar" />
                </span>{" "}
                <span
                  style={{
                    paddingLeft: "12px",
                  }}
                >
                  contact@hactric.com
                </span>
              </div>
              <div>
                <span>
                  <img src={Mobile} alt="MobileAvatar" />
                </span>{" "}
                <span
                  style={{
                    paddingLeft: "12px",
                  }}
                >
                  +92 321 6848008
                </span>
              </div>
              <div >
                <span>
                  <img src={Location} alt="locationAvatar" />
                </span> {" "}
                <span
                  style={{
                    paddingLeft: "12px",
                  }}
                >
                  Technology Incubation Center, NUST H-12, Islamabad, Pakistan
                </span>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
}
