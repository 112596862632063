import { Grid } from "@mui/material";
import React from "react";
import Challenge from "../../assets/portfolio/Challenge.png";
import  "../../styles/challenges.css";
import whiteCircle from "../../assets/portfolio/whiteCircle.png";
import PropTypes from "prop-types";
export default function Challenges({ filterProject }) {
  return (
    <Grid
      container
      className={`${"challengeContainer"} Webcontainer mt-5`}
    >
      <Grid item xs={12} sm={12} md={6} className={`${"ChallengeAvatar"}`}>
        <img src={Challenge} alt="ChallengeAvatar" />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        className={`${"ChallengesExplain"}`}
      >
        <div className={"challengeHead"}>
          <p>The Challenge</p>
        </div>
        <div>
          {filterProject?.ChallengesFaced?.map((e, i) => (
            <div key={i}>
              <div className={"challengeImage"}>
                <img src={whiteCircle} alt="CircleAvatar" />
              </div>
              <div className={"challengeinfo"}>
                <p>{e?.Heading}</p>
                <span>{e?.Description}</span>
              </div>
            </div>
          ))}
        </div>
      </Grid>
    </Grid>
  );
}
Challenges.propTypes = {
  filterProject: PropTypes.object.isRequired,
};
