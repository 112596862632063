import React from "react";
import Vector2 from "../../assets/servicePageImages/Vector2.png";
import { Grid } from "@mui/material";
import "../../styles/services.css";
export default function ServiceVector2() {
  return (
    <Grid container>
      <Grid item xs={12} className={"serviceMask"}>
        <img src={Vector2} alt="VectorAvatar" />
      </Grid>
    </Grid>
  );
}
