import React from "react";
import "../../styles/home.css";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
export default function Mask2({ TagName, xsGrid, smGrid, mdGrid, TagInfo }) {
  return (
    <Grid container>
      <Grid item xs={xsGrid} sm={smGrid} md={mdGrid}>
        <p className={"ServiceTitle"}>{TagName}</p>
        <div className={"verticalBar"}></div>
        <p className={"TagInfo"}>{TagInfo}</p>
      </Grid>
    </Grid>
  );
}
Mask2.propTypes = {
  TagName: PropTypes.string,
  xsGrid: PropTypes.number,
  smGrid: PropTypes.number,
  mdGrid: PropTypes.number,
  TagInfo: PropTypes.string,
};
