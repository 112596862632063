import React, { useEffect, useState } from "react";
import jobviewMask from "../../assets/jobviewMask.png";
import jobviewImage from "../../assets/jobviewImage.png";
import "../../styles/jobDetail.css";
import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GetAllCurrentOpening } from "../../api/jobs";
import JobViewForm from "../jobs/JobForm";

export default function JobDetailMainPage() {
  const { jobId } = useParams();
  const dispatch = useDispatch();
  const { CurrentJobs } = useSelector((state) => state?.CurrentJobs);
  const [findJob, setfindJob] = useState({});

  useEffect(() => {
    if (!jobId) return;
    if (CurrentJobs.length > 0) {
      const findJob = CurrentJobs?.find((e) => e?._id === jobId);
      setfindJob(findJob);
      return;
    }
    GetAllCurrentOpening(dispatch);
  }, [jobId, CurrentJobs]);

  return (
    <>
      <Grid item xs={12} className={"jobImage"}>
        <img src={jobviewImage} alt="jobAvatar" />
        <img src={jobviewMask} alt="MaskAvatar" />
        <div className={"JobDateandName"}>
          <p>{new Date(findJob?.createdAt).toLocaleDateString()}</p>
          <p>{findJob?.jobTitle}</p>
        </div>
      </Grid>
      <Grid container className="Webcontainer">
        <Grid item xs={12} sm={12} md={6} className={`${"jobDetails"}`}>
          <div className={"deadLines"}>
            <span>DEADLINE: </span>
            <span>{new Date(findJob?.DeadLineDate).toLocaleDateString()}</span>
            <p className={"bar"}></p>
          </div>
          <div className={"hactricInfo"}>
            <p>
              Hactric Solutions (Pvt) Ltd. is looking for a passionate
              <span> {findJob?.jobTitle} </span>
              to join our team !
            </p>
          </div>

          <div className={"JobRequiremnt"}>
            <p>Job Requirements:</p>
            <p className={"bar"}></p>
          </div>
          <div className={"JobDescription"}>
            <ul>
              {findJob?.description?.map((a, i) => (
                <li key={i}>{a}</li>
              ))}
            </ul>
          </div>
          <div className={"deadLines"}>
            <span>Add-ons:</span>
          </div>
          <div className={"addonsQualities"}>
            <ul>
              {findJob?.addsOns?.map((e, index) => (
                <li key={index}>{e}</li>
              ))}
            </ul>
          </div>
          <div className={"shortListInfo"}>
            <p>
              A detailed email with instructions will be sent to the shortlisted
              candidates followed by a final interview!
            </p>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <div className={"horizontalLine"}></div>
          <Box
            sx={{
              padding: { xs: "1rem", sm: "2rem", md: "0 1rem 0 4rem" },
            }}
          >
            <JobViewForm jobId={jobId} name={findJob?.jobTitle} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
