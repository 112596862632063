import React from "react";
export default function SmallImageHandler({
  LinksTrue,
  ImageLinks,
  LinkImage,
}) {
  return (
    <div className={"handler"}>
      {LinksTrue ? (
        <div className={"linkandImage"}>
          <a href={ImageLinks}>
            <img
              src={LinkImage}
              alt="linkAvatar"
              style={{
                borderRadius: "50px",
                marginRight: "0.5rem",
              }}
            />
          </a>
        </div>
      ) : (
        <img src={LinkImage} alt="LinkImageAvatar" />
      )}
    </div>
  );
}
