import React, { useState, useRef, useEffect } from "react";
import { Grid, Button } from "@mui/material";
import webLogo from "../../assets/webLogo.png";
import barsImage from "../../assets/barsImage.png";
import "../../styles/header.css";
import "../../styles/header.scss";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { styled } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { NavLink } from "react-router-dom";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Header = (props) => {
  const [open, setOpen] = useState(false);
  const [dropdown, setDrowndown] = useState(false);
  const closedropDown = useRef();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const toggleOpen = () => setDrowndown(!dropdown);

  useEffect(() => {
    const handler = (event) => {
      if (!closedropDown?.current?.contains(event.target)) setDrowndown(false);
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <>
      <Grid container ref={closedropDown} className={"HeaderContainer"}>
        <Grid item sm={4} md={3} className="logo">
          <NavLink to="/">
            <img
              // loader={myLoader}
              src={webLogo}
              alt="weblogo"
            />
          </NavLink>
        </Grid>
        <Grid item sm={4} md={6.5} className={"headervalues"}>
          {/* <ul>
            <li>
              <NavLink to="/hactric/aboutus">About Us</NavLink>
            </li>
            <li>
              <div className={"dropdown"}>
                <button className={"dropbtn"} onClick={toggleOpen}>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <span>Services</span>
                    <span>
                      <ExpandMoreIcon
                        sx={{
                          color: "white",
                        }}
                      />
                    </span>
                  </div>
                </button>
                {dropdown ? (
                  <div className={"dropdownContent"}>
                    <NavLink to="/hactric/webdevelopment">
                      Web Development
                    </NavLink>
                    <NavLink to="/hactric/appdevelopment">
                      App Development
                    </NavLink>
                    <NavLink to="http://hactric-hr.s3-website.ap-south-1.amazonaws.com">
                      HR Recruitment
                    </NavLink> 
                  </div>
                ) : (
                  ""
                )}
              </div>
            </li>
            <li>
              <NavLink to="/hactric/careers">Careers</NavLink>
            </li>
          </ul> */}
        </Grid>
        {/* <Grid item sm={4} md={2.5} className={"getQuote"}>
          <NavLink to="/hactric/quoteauotamation">Get A Quote</NavLink>
        </Grid>{" "} */}
      </Grid>

      {/* For Mobile Screen View */}

      <Grid container className={"MobileContainer"}>
        <NavLink to="/">
          <div className="logo">
            <img
              // loader={myLoader}
              src={webLogo}
              alt="weblogo"
            />
          </div>
        </NavLink>

        <div className={"barsContainer"}>
          <Button onClick={handleOpen}>
            <img src={barsImage} alt="logo" />
          </Button>
        </div>
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box className={"Modal"}>
              <div className={"modalHeader"}>
                <div className="logo">
                  <img
                    // loader={myLoader}
                    src={webLogo}
                    alt="weblogo"
                  />
                </div>
                <br />
                <Box
                  sx={{
                    color: { xs: "white", sm: "black", padding: "1rem 0.5rem" },
                  }}
                >
                  <CloseRoundedIcon onClick={handleClose} />
                </Box>
              </div>
              <div className={"Modalheadervalues"}>
                <ul>
                  <li>
                    <NavLink to="/hactric/aboutus">About Us</NavLink>
                  </li>
                  <li>
                    <CardActions>
                      <p className={"serviceStyle"}>Services</p>
                      <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon className={"arrowstyle"} />
                      </ExpandMore>
                    </CardActions>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                      <CardContent>
                        <Box className={"dropdownContent"}>
                          <NavLink to="/hactric/webdevelopment">
                            Web Development
                          </NavLink>
                        </Box>
                        <Box className={"dropdownContent"}>
                          <NavLink to="/hactric/appdevelopment">
                            App Development
                          </NavLink>
                        </Box>
                        {/* <Box className={"dropdownContent"}>
                          <NavLink to="/hactric/appdevelopment">
                            HR Recruitment
                          </NavLink>
                        </Box> */}
                      </CardContent>
                    </Collapse>
                  </li>
                  <li>
                    <NavLink to="/hactric/careers">Careers</NavLink>
                  </li>
                </ul>
              </div>
              <div>
                <div className={"ModalgetQuote"}>
                  <NavLink to="/hactric/quoteauotamation">Get Quote</NavLink>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      </Grid>
    </>
  );
};

Header.propTypes = {};

export default Header;
