import React from "react";
import webdev1 from "../../assets/servicePageImages/webDevelopment/webdev1.png";
import webdev2 from "../../assets/servicePageImages/webDevelopment/webdev2.png";
import webdev3 from "../../assets/servicePageImages/webDevelopment/webdev3.png";
import webdev4 from "../../assets/servicePageImages/webDevelopment/webdev4.png";
import webdev5 from "../../assets/servicePageImages/webDevelopment/webdev5.png";
import { Box } from "@mui/material";
import Layout from "../../layout/Layout";
import ServiceVector from "../services/ServiceVector";
import SuccessSide from "../success/SuccessSide";
import ServiceVector2 from "../services/ServiceVector2";
import WorkPortfolio from "../work/WorkPortfolio";
import TalkWithUs from "../talk/TalkWithUs";
import WebAndAppMainPage from "./WebAndAppMainPage";
import WedDevelopmentServices from "../services/WebDevelopmentServices";

export default function WebDevelopmentMainPage() {
  return (
    <Layout>
      <WebAndAppMainPage
        PageTitle="Web Development"
        pageInfo="We collaborate with you to automate your business operations, decrease human errors to increase accuracy and revenue, lower costs, and empower you to outperform your competitors."
        image1={webdev1}
        image2={webdev2}
        image3={webdev3}
        image4={webdev5}
        image5={webdev4}
        btnLink="/hactric/addApp"
        btnName="GET STARTED"
      />
      <Box
        sx={{
          zIndex: "-1",
          position: "relative",
          top: { xs: "0", sm: "0", md: "-117px" },
        }}
      >
        <ServiceVector />
      </Box>
      <Box sx={{ mt: { xs: "200px", sm: "100px", md: "-230px" } }}>
        <SuccessSide
          SuccessTitle="your business a legendary"
          Successinfo="We employ a systematic approach to delivering projects of various complexity and sizes."
        />
      </Box>
      <ServiceVector2 />
      <WedDevelopmentServices />
      <ServiceVector />
      {/* <WorkPortfolio /> */}
      <div className="talkWithus">
        {/* <div className="webMargin"> */}
          <TalkWithUs />
        </div>
      {/* </div> */}
    </Layout>
  );
}
