import React, { useEffect } from "react";
import Layout from "../layout/Layout";
// import "../styles/home.css";
import "../styles/home.scss";
import "../styles/homeService.scss";
import { Grid, Button, Box } from "@mui/material";
import Group from "../assets/Group.png";
import Carousel from "react-multi-carousel";
import Services from "../components/services/Services";
import HomeMask from "../assets/HomeMask.png";
import { animateScroll } from "react-scroll";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const renderLeft = () => (
  <Grid item xs={12} sm={12} md={6} className={"frontTitle"} id="frontTitle">
    <div className="TagLine">
      <h3>We provide</h3>
      <p>Smart Solutions for</p>

      <Carousel
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlaySpeed={2000}
        autoPlay={true}
        keyBoardControl={true}
        containerClass="carousel-container displaytechimages"
        removeArrowOnDeviceType={[
          "tablet",
          "mobile",
          "desktop",
          "superLargeDesktop",
        ]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        className={"styleOfContainer"}
      >
        <p>App Development</p>
        <p>Web Development</p>
        {/* <p>HR Recruitment</p> */}
      </Carousel>
      {/* <Grid item xs={12}></Grid> */}
      <a href="/hactric/addApp">
        <Button>Get Started</Button>
      </a>
    </div>
  </Grid>
);

const renderRight = () => (
  <Grid
    item
    xs={12}
    sm={12}
    md={6}
    className={"frontimg"}
    // sx={{ mt: { xs: 0, sm: 0, md: 0, lg: "-8rem" } }}
  >
    <img
      // loader={myLoader}
      src={Group}
      alt="homeAvatar"
    />
  </Grid>
);

const Home = (props) => {
  return (
    <Layout>
      <Grid container className={"Webcontainer HomeContainer"}>
        {renderLeft()}
        {renderRight()}
      </Grid>

      <Grid item xs={12} className="homeMaskContainer">
        <img src={HomeMask} alt="MaskAvatar" style={{ width: "100%" }} />
      </Grid>

      <Box
        sx={{
          margin: {
            xs: "-400px 0 0",
            sm: "-400px 0 0 0",
            md: "-560px 0 0 0",
          },
        }}
        id="servicesSection"
      >
        <Services />
      </Box>
    </Layout>
  );
};

Home.propTypes = {};

export default Home;
