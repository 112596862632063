import React from "react";
import Carousel from "react-multi-carousel";
import { Grid } from "@mui/material";
import "../../styles/services.css";
import dev1 from "../../assets/servicePageImages/dev1.png";
import dev2 from "../../assets/servicePageImages/dev2.png";
import dev3 from "../../assets/servicePageImages/dev3.png";
import dev4 from "../../assets/servicePageImages/dev4.png";
import dev5 from "../../assets/servicePageImages/dev5.png";
import dev6 from "../../assets/servicePageImages/dev6.png";
import TechImg from "../tech/TechImg";

export default function DevelopmentServices() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      paritialVisibilityGutter: 60,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 50,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1.5,
      paritialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 30,
    },
  };
  return (
    <>
      <Grid
        container
        className={`${"WedDevelopmentServices"}`}
        sx={{ marginTop: "-350px" }}
      >
        <Grid item xs={12} sm={12} className={"devlopmentTag"}>
          <p>We offer custom app development services for</p>
          <div className={"verticalBar"}></div>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ m: "1rem 1rem" }}>
          <TechImg
            altName="techAvatar"
            languageImg={dev1}
            languageName="Multimedia &
          Enterprise Apps"
            isBold={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ m: "1rem 1rem" }}>
          <TechImg
            altName="techAvatar"
            languageImg={dev2}
            languageName="Online Booking &
            E-Commerce"
            isBold={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ m: "1rem 1rem" }}>
          <TechImg
            altName="techAvatar"
            languageImg={dev3}
            languageName="Progressive Web Apps"
            isBold={false}
          />{" "}
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ m: "1rem 1rem" }}>
          <TechImg
            altName="techAvatar"
            languageImg={dev4}
            languageName="Travel & Navigation"
            isBold={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ m: "1rem 1rem" }}>
          <TechImg
            altName="techAvatar"
            languageImg={dev5}
            languageName="Financial & Utility"
            isBold={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ m: "1rem 1rem" }}>
          <TechImg
            altName="techAvatar"
            languageImg={dev6}
            languageName="Support & Maintenance"
            isBold={false}
          />
        </Grid>
      </Grid>
      <div className={"ServiceWebScroll"}>
        <Grid container className={`container`} sx={{ marginTop: "-50px" }}>
          <Grid item xs={12} sm={12} className={"devlopmentTag"}>
            <p>Development solutions designed for every business</p>
            <div className={"verticalBar"}></div>
          </Grid>
        </Grid>
        <div className={"ServiceScrollView"}>
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            partialVisbile={false}
            responsive={responsive}
            ssr={false} // means to render carousel on server-side.
            infinite={true}
            autoPlaySpeed={2000}
            autoPlay={true}
            keyBoardControl={true}
            containerClass="carousel-container"
            removeArrowOnDeviceType={[
              "tablet",
              "mobile",
              "desktop",
              "superLargeDesktop",
            ]}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            <div className={"webServicesScrollView"}>
              <TechImg
                altName="techAvatar"
                languageImg={dev1}
                languageName="Multimedia & Enterprise Apps"
                isBold={false}
              />
            </div>
            <div className={"webServicesScrollView"}>
              <TechImg
                altName="techAvatar"
                languageImg={dev2}
                languageName="Online Booking &
                E-Commerce"
                isBold={false}
              />
            </div>
            <div className={"webServicesScrollView"}>
              <TechImg
                altName="techAvatar"
                languageImg={dev3}
                languageName="Progressive Web Apps"
                isBold={false}
              />
            </div>
            <div className={"webServicesScrollView"}>
              <TechImg
                altName="techAvatar"
                languageImg={dev4}
                languageName="Travel & Navigation"
                isBold={false}
              />
            </div>
            <div className={"webServicesScrollView"}>
              <TechImg
                altName="techAvatar"
                languageImg={dev5}
                languageName="Financial & Utility"
                isBold={false}
              />
            </div>
            <div className={"webServicesScrollView"}>
              <TechImg
                altName="techAvatar"
                languageImg={dev6}
                languageName="Support & Maintenance"
                isBold={false}
              />
            </div>
          </Carousel>
        </div>
      </div>
    </>
  );
}
