import React, { useState } from "react";
import { Grid } from "@mui/material";
import  "../../styles/solution.css";
import whiteCircle from "../../assets/portfolio/whiteCircle.png";
import PropTypes from "prop-types";

export default function Solutions({ filterProject }) {
  const [index, setIndex] = useState(0);

  const setIndexIncrement = (index) => setIndex(index);
  const setIndexDecrement = (index) => setIndex(index);

  const indexHandler = (isNext) => {
    if (isNext) {
      if (index !== filterProject.SecondaryScreenShorts.length - 1)
        setIndexIncrement(index + 1);
    } else {
      if (index !== 0) setIndexDecrement(index - 1);
    }
  };

  return (
    <>
      <Grid
        container
        sx={{
          padding: "2rem",
        }}
        className="Webcontainer"
      >
        <Grid item xs={12}>
          <p className={"SolutionHead"}>The Solution</p>
        </Grid>
        {filterProject?.SolutionProvided?.map((e, i) => (
          <Grid key={i} item xs={12} sm={12} md={6}>
            <div className={"SolutionContainer"}>
              <div className={"solImg"}>
                <img src={whiteCircle} alt="circleAvatar" />
              </div>
              <div className={"SolutionInfo"}>
                <p> {e?.Heading}</p>
                <span>{e?.Description}</span>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
      <div
        id="carouselExampleIndicators"
        className="carousel slide container mt-5"
        data-ride="carousel"
      >
        <ol className="carousel-indicators">
          {filterProject &&
            filterProject?.SecondaryScreenShorts?.length &&
            filterProject?.SecondaryScreenShorts.map((e, i) => (
              <li
                key={i}
                data-target="#carouselExampleIndicators"
                data-slide-to={i}
                className={i == index ? "active" : ""}
              ></li>
            ))}
        </ol>
        <div className="carousel-inner">
          {filterProject &&
            filterProject?.SecondaryScreenShorts?.map((e, i) => ( 
              <div
                key={i}
                className={`${"sliderWrapper"} carousel-item ${
                  i == index ? "active" : ""
                }`}
              >
                <img
                  className="d-block w-100 h-100"
                  src={e}
                  alt="First slide"
                  width={1000}
                  height={80}
                  // style={{
                  //   objectFit: "cover",
                  // }}
                />
              </div>
            ))}
        </div>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <a
            className="carousel-control-prev"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev"
            onClick={() => indexHandler(false)}
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next"
            onClick={() => indexHandler(true)}
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
    </>
  );
}
Solutions.propTypes = {
  filterProject: PropTypes.object.isRequired,
};
