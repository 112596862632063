import React from "react";
import "../../styles/carrier.css";
import { Grid } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function JobPlacingContainer({
  JobImage,
  JobTitle,
  JobInfo,
  data,
}) {
  return (
    // <div className={"JobPlacingContainer"}>
    <>
      <Grid item xs={12} sm={12} md={6} className={"JobPlacingImage"}>
        <img
          width={100}
          height={100}
          style={{
            position: "relative !important",
            objectFit: "scale-down",
            // top: "4rem",
          }}
          src={JobImage}
          alt="JobImageAvatar"
        />
        <div className={"titleAndInfo"}>
          <p>{data?.jobTitle}</p>
          <p>{data?.description?.[0]}</p>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={"JobPlacingLink"}>
        <a href={`/hactric/${data?._id}`}>
          Apply
          <ArrowForwardIcon
            sx={{
              color: "#f35530",
              marginLeft: "0.5rem",
            }}
            fontSize="small"
          />
        </a>
      </Grid>
      <Grid item xs={12} className={"JobBorderContainer"}></Grid>
    </>
    // </div>
  );
}
