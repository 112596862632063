import React from "react";
import { Box, Grid } from "@mui/material";
import "../../styles/aboutUs.css";
import Circle from "../../assets/aboutUS/Circle.png";
import Mask2 from "../mask/Mask2";
export default function OurStory() {
  return (
    <div className={"storyContainer"}>
      <Mask2
        TagName="Our Story"
        xsGrid={12}
        smGrid={12}
        mdGrid={12}
        TagInfo=""
      />
      <Grid
        container
        sx={{ marginTop: "15rem", display: "flex", justifyContent: "center" }}
      >
        <div className={"OurStories"}>
          <div className={"storiesWrapper"}>
            <p>
              Eget donec porttitor diam in maecenas vestibulum duis gravida hac
              adipiscing egestas non risus auctor in suscipit cursus amet sed
            </p>
            <div className={"storiesStep"}>
              <div className={"storyStepBorder"}></div>
            </div>

            <span>2022</span>
            <a src={Circle} alt="circleAvatar" />
          </div>
          <div className={"storiesWrapper"}>
            <span className={"conenctInfoyear"}>2021</span>
            <a src={Circle} alt="circleAvatar" />

            <div className={"storiesStep"}>
              <div className={"storyStepBorder"}></div>
            </div>
            <p className={"conenctInfo"}>
              Eget donec porttitor diam in maecenas vestibulum duis gravida hac
              adipiscing egestas non risus auctor in suscipit cursus amet sed
            </p>
          </div>
          <div className={"storiesWrapper"}>
            <p>
              Eget donec porttitor diam in maecenas vestibulum duis gravida hac
              adipiscing egestas non risus auctor in suscipit cursus amet sed
            </p>
            <div className={"storiesStep"}>
              <div className={"storyStepBorder"}></div>
            </div>
            <span>2020</span>
            <a src={Circle} alt="circleAvatar" />
          </div>
          <div className={"storiesWrapper"}>
            <span className={"conenctInfoyear"}>2019</span>
            <a src={Circle} alt="circleAvatar" />
            <div className={"storiesStep"}>
              <div className={"storyStepBorder"}></div>
            </div>
            <p>
              Eget donec porttitor diam in maecenas vestibulum duis gravida hac
              adipiscing egestas non risus auctor in suscipit cursus amet sed
            </p>
          </div>
        </div>
      </Grid>
      ;
    </div>
  );
}
