import React from "react";
import { Grid } from "@mui/material";
import "../../styles/services.css";
import PropTypes from "prop-types";
import ServiceDot from "../../assets/servicePageImages/ServiceDot.png";
export default function SuccessSide({ SuccessTitle, Successinfo }) {
  return (
    <Grid container className="Webcontainer">
      <Grid item xs={12} className={"SuccessTitle"}>
        <span>How we make </span>
        <span> {SuccessTitle}</span>
      </Grid>
      <Grid item xs={12} className={"SuccessInfo"}>
        {Successinfo}
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={3} className={"DiscoveryHeader"}>
        <div className={"NumberValues"}>
          <img src={ServiceDot} alt="serviceDotAvatar" />
          <p>01</p>
        </div>
        <p className={"Header"}>Discovery</p>
        <ul>
          <li>
            Understands your project <p>requirement</p>
          </li>
          <li>Identify your business goals</li>
          <li>Define how to measure success</li>
        </ul>
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={3} className={"DiscoveryHeader"}>
        <div className={"NumberValues"}>
          <img src={ServiceDot} alt="serviceDotAvatar" />
          <p>02</p>
        </div>
        <p className={"Header"}>Initiation</p>
        <ul>
          <li>Design splendid UX</li>
          <li>Apply the best-suited plan</li>
          <li>Engage expert engineers </li>
          <li>Establish a process </li>
          <li>Keeps you updated</li>
        </ul>
      </Grid>
      <Grid item xs={12} sm={4} md={3} className={"DiscoveryHeader"}>
        <div className={"NumberValues"}>
          <img src={ServiceDot} alt="serviceDotAvatar" />
          <p>03</p>
        </div>
        <p className={"Header"}>Delivery</p>
        <ul>
          <li>
            Conduct a manual and <p>automated testing</p>
          </li>
          <li>Identify and fix the bugs</li>
          <li>Deliver a bug-free product</li>
        </ul>
      </Grid>
      <Grid item xs={12} sm={4} md={3} className={"DiscoveryHeader"}>
        <div className={"NumberValues"}>
          <img src={ServiceDot} alt="serviceDotAvatar" />
          <p>04</p>
        </div>
        <p className={"Header"}>Maintenance</p>
        <ul>
          <li>24*7 support and maintenance</li>
          <li>Keeps your product in service Enables</li>
          <li>Enables you to focus on your business growth</li>
        </ul>
      </Grid>
    </Grid>
  );
}

SuccessSide.propTypes = {
  SuccessTitle: PropTypes.string,
  Successinfo: PropTypes.string,
};
