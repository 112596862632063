const initialState = { applicationStatus: [] };

export default function ApplicationStatusReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_ALL_APPLICATIONS":
      return { ...state, applicationStatus: action.payload };
    default:
      return state;
  }
}
