import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import PropTypes from "prop-types";
import "../../styles/quoteAutomation.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Checkbox from "@mui/material/Checkbox";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import QuoteMaker from "./QuoteMaker";
import api from "../../api/request";

const SendingQuote = ({
  handlerPrice,
  currentIndex,
  setSelectedData,
  setCurrentIndex,
  list,
  value,
  setValue,
  checked,
}) => {
  console.log("handlerPrice", handlerPrice);
  const [loading, setLoading] = useState(false);
  const [EmailInput, setEmailInput] = useState("");
  const router = useNavigate();

  const Blockchaininfo = handlerPrice?.filter(
    (e) =>
      ![
        "Artificial Intelligence",
        "Augmented Reality",
        "Virtual Reality",
        "Internet of Things",
        "Blockchain",
      ].includes(e.title)
  );

  const priceAmount =
    handlerPrice &&
    handlerPrice?.reduce((a, b) => {
      return a + b.price;
    }, 0);

  const WithoutBlockChain =
    Blockchaininfo && Blockchaininfo?.reduce((a, b) => (a = a + b.price), 0);

  const HandleEmail = async () => {
    try {
      const body = {
        email: EmailInput,
        WithoutBlockChainPrice: WithoutBlockChain,
        WithBlockChainPrice: priceAmount,
      };

      if (EmailInput === "" || !EmailInput || EmailInput === null) {
        toast.error("Filed is required");
      } else {
        setLoading(true);
        await api.post(
          "/api/QuoteAutomation/QuoteAutomationRoute/sendmail",
          body
        );
        setLoading(false);
        toast.success("Email Send");
        setTimeout(() => {
          router.push("/hactric/AutomationEmailSend");
        }, 1000);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message || error);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} className={"selectPlatform"}>
        <p>Your app estimate</p>
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={"Tagprice"}>
        <p>${priceAmount}</p>
        <p>*Blockchain and VR included</p>
      </Grid>
      {checked ? (
        <Grid item xs={12} sm={12} md={6} className={"Tagprice"}>
          <p>${WithoutBlockChain}</p>
          <p>*Without Blockchain and VR</p>
        </Grid>
      ) : (
        ""
      )}
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1rem",
        }}
      >
        <Paper elevation={3} className={"estimateTitle"}>
          <p> Get the quote in your inbox</p>
          <TextField
            label="email@example.com"
            sx={{
              width: "100%",
            }}
            onChange={(e) => setEmailInput(e?.target?.value)}
          />
          {loading ? <CircularProgress /> : ""}
          <Button onClick={HandleEmail}>Send me</Button>
        </Paper>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Grid>
      <div
        className={"previousBtn"}
        style={{
          marginTop: "1rem",
        }}
      >
        <Button
          onClick={() => {
            setCurrentIndex(currentIndex - 1);
            setSelectedData(list[currentIndex - 2]);
            setValue(value - 1);
          }}
        >
          <span
            style={{
              marginTop: "9px",
            }}
          >
            <ArrowBackIcon fontSize="small" />
          </span>
          previous Step
        </Button>
      </div>
    </Grid>
  );
};

export const PlateFormSelection = ({ handleChange, value, setValue }) => {
  const [list, setList] = useState("");
  const [selectedData, setSelectedData] = useState({});
  const [currentIndex, setCurrentIndex] = useState("");
  const [handlerPrice, setHandlerPrice] = useState([]);
  const [checked, setChecked] = React.useState(false);
  const CheckBoxhandler = (event) => {
    setChecked(event.target.checked);
  };
  const getQuoteAutomation = async () => {
    const resp = await api?.get("/api/QuoteAutomation/QuoteAutomationRoute");

    const steppers = resp?.data?.resp;
    setList(steppers);
    setSelectedData(steppers[0]);
    setCurrentIndex(1);
  };

  const handleQuotes = (spec) => {
    const { _id } = spec;

    const findData =
      selectedData &&
      selectedData?.Specifications?.find(
        (e, i) => String(e?._id) === String(_id)
      );

    const isSpecExist = handlerPrice.findIndex(
      (e) => String(e?._id) === String(_id)
    );

    let [...rest] = handlerPrice;
    if (isSpecExist === -1) {
      //if not exist than add
      rest = [...handlerPrice, findData];
    } else {
      if (String(findData?._id) === String(handlerPrice[isSpecExist]._id)) {
        //if exist than remove exist
        rest = handlerPrice.filter(
          (e) => String(e._id) !== String(findData?._id)
        );
      } else {
        //if same exist than changes happen on that index
        rest[isSpecExist] = findData;
      }
    }

    setHandlerPrice(rest);
  };
  useEffect(() => {
    getQuoteAutomation();
  }, []);

  return (
    <div className={"selectPlatformContainer"}>
      <Grid container>
        <Grid item xs={12} sm={12} className={"selectPlatform"}>
          <p>{currentIndex > list?.length ? "" : selectedData.title}</p>
        </Grid>
        {selectedData?.Specifications?.length ? (
          selectedData?.Specifications?.map((e, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <QuoteMaker
                TagTitle={e?.title}
                TagImage={e?.Image}
                isAssending={true}
                handleQuotes={() => handleQuotes(e)}
                TagInfo={e?.info}
                data={handlerPrice}
                isActive={
                  handlerPrice?.length
                    ? handlerPrice.find((x) => String(x._id) === String(e._id))
                    : ""
                }
              />
            </Grid>
          ))
        ) : currentIndex > list?.length ? (
          ""
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <CircularProgress size={50} />
            {/* <h5 style={{ color: "white" }}>No Data</h5> */}
          </div>
        )}
      </Grid>
      {currentIndex === 8 ? (
        <>
          <div className={"CheckBoxContainer"}>
            <Checkbox
              checked={checked}
              onChange={CheckBoxhandler}
              inputProps={{ "aria-label": "controlled" }}
            />

            <p>
              Show me the quote without including these technologies as well
            </p>
          </div>
        </>
      ) : (
        ""
      )}
      {currentIndex > list?.length ? (
        ""
      ) : (
        <>
          <div className={"BtnContainer"}>
            <div className={"pre_continueBtn"}>
              <div className={"previousBtn"}>
                <Button
                  onClick={() => {
                    setCurrentIndex(currentIndex - 1);
                    setSelectedData(list[currentIndex - 2]);
                    setValue(value - 1);
                  }}
                  disabled={currentIndex === 1}
                >
                  <span
                    style={{
                      marginTop: "9px",
                    }}
                  >
                    <ArrowBackIcon fontSize="small" />
                  </span>
                  previous Step
                </Button>
              </div>
            </div>
            <div className={"continueBtn"}>
              {/* {currentIndex >= list?.length ? ( */}
              <Button
                onClick={() => {
                  setSelectedData(list[currentIndex]);
                  setCurrentIndex(currentIndex + 1);
                  setValue(value + 1);
                }}
              >
                Continue
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ArrowForwardIcon fontSize="1rem" />
                </span>
              </Button>
            </div>
          </div>
        </>
      )}
      {currentIndex > list?.length ? (
        <SendingQuote
          checked={checked}
          setChecked={setChecked}
          handlerPrice={handlerPrice}
          currentIndex={currentIndex}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          setCurrentIndex={setCurrentIndex}
          list={list}
          value={value}
          setValue={setValue}
        />
      ) : (
        ""
      )}
    </div>
  );
};
PlateFormSelection.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
};
