import React, { useState } from "react";
import "../../styles/quoteAutomation.css";
import activeTick from "../../assets/QuoteAutomation/activeTick.png";

export default function QuoteMaker({
  TagTitle,
  TagImage,
  TagInfo,
  handleQuotes,
  height,
  isActive,
}) {
  return (
    <>
      <div
        onClick={() => handleQuotes(TagTitle)}
        className={`${"QuoteCard"} ${isActive ? "activeQuote" : ""}`}
        style={{
          height: height,
        }}
      >
        <div className={"activeTick"}>
          {isActive && <img src={activeTick} alt="AcitveTick" />}
        </div>

        <p className={"quoteTitle"}>{TagTitle}</p>
        <img
          src={TagImage}
          alt="TagAvatar"
          width={300}
          height={100}
          style={{
            position: "relative !important",
            objectFit: "scale-down",
            // top: "4rem",
          }}
        />
        <p>{TagInfo}</p>
      </div>
    </>
  );
}
