const initialState = { QuoteAutomation: [] };

export default function QuoteAutomationReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_QUOTE_AUTOMATION":
      return {
        ...state,
        QuoteAutomation: action.payload,
      };

    default:
      return state;
  }
}
