import React, { useEffect } from "react";
import { Grid } from "@mui/material";

import FooterMask from "../../assets/footerMask.png";
import "../../styles/footer.css";
import Location from "../../assets/FooterImage/Location.png";
import Message from "../../assets/FooterImage/Message.png";
import Mobile from "../../assets/FooterImage/Mobile.png";
import HactricWhitelogo from "../../assets/HactricWhitelogo.png";
import FaceBook from "../../assets/icons/FaceBook.svg";
import Linkedin from "../../assets/icons/Linkedin.svg";
import Insta from "../../assets/icons/Insta.svg";
import Behance from "../../assets/icons/BehanceIcon.svg";
import WhatsappIcon from "../../assets/icons/WhatsappIcon.svg";
import SmallImageHandler from "../smallImageHandler/SmallImageHandler";
import { Link, NavLink, useNavigate } from "react-router-dom";
import FooterEnd from "./FooterEnd";
import { scrollToTop } from "react-scroll/modules/mixins/animate-scroll";
export default function Footer() {
  const navigate = useNavigate();
  const handleLinkClick = (event, path) => {
    event.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate(path);
  };

  return (
    <div className={"footerContainer"}>
      <div className={`${"homeMask"}`}>
        <img src={FooterMask} alt="FooterMask" />
      </div>
      <Grid container className="Webcontainer">
        <Grid item xs={12} sm={4} md={3}>
          <div className={"hactriclogo"}>
            <img src={HactricWhitelogo} alt="WhiteLogoMask" />
            <p>
              Technology driven and socially sound, Hactric Solutions (Pvt) Ltd.
              aims to find innovative solutions to society’s problems.
            </p>
          </div>
          <Grid item xs={12} className={"socialMediaAccounts"}>
            <SmallImageHandler
              LinksTrue={true}
              ImageLinks=""
              LinkImage={FaceBook}
            />
            <SmallImageHandler
              LinksTrue={true}
              ImageLinks=""
              LinkImage={Linkedin}
            />
            <SmallImageHandler
              LinksTrue={true}
              ImageLinks=""
              LinkImage={Insta}
            />
            <SmallImageHandler
              LinksTrue={true}
              ImageLinks=""
              LinkImage={Behance}
            />
            <SmallImageHandler
              LinksTrue={true}
              ImageLinks=""
              LinkImage={WhatsappIcon}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} md={3} className={"quickLink"}>
          {/* <h3>Quick Links</h3> */}
          <ul>
            {/* <li>
              <Link
                to="/hactric/aboutus"
                onClick={(event) => handleLinkClick(event, "/hactric/aboutus")}
                // className={"scrollToTop"}
              >
                About Us
              </Link>
            </li>
            <li> */}
              {/* <NavLink to="/hactric/appdevelopment">Services</NavLink> */}
            {/* </li> */}
            {/* <li>
              <NavLink
                to="/hactric/careers"
                onClick={(event) => handleLinkClick(event, "/hactric/careers")}
              >
                careers
              </NavLink>
              <span>We’re hiring</span>
            </li> */}
          </ul>
        </Grid>
        <Grid item xs={12} sm={4} md={3} className={"quickLink"}>
          <h3>Services</h3>
          <ul>
            <li>
              <NavLink
                to="/hactric/appdevelopment"
                onClick={(event) =>
                  handleLinkClick(event, "/hactric/appdevelopment")
                }
              >
                App Development
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/hactric/webdevelopment"
                onClick={(event) =>
                  handleLinkClick(event, "/hactric/webdevelopment")
                }
              >
                Web Development
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/">HR Recruitment</NavLink>
            </li> */}
          </ul>
        </Grid>
        <Grid item xs={12} sm={4} md={3} className={"reachus"}>
          <h3>Reach us</h3>
          <p>
            <span>
              <img src={Message} alt="MessageAvatar" />
            </span>{" "}
            <span
              style={{
                paddingLeft: "12px",
              }}
            >
              contact@hactric.com
            </span>
          </p>
          <p>
            <span
              style={{
                textAlign: "center",
              }}
            >
              <img src={Mobile} alt="MobileAvatar" />
            </span>{" "}
            <span
              style={{
                paddingLeft: "12px",
              }}
            >
              +92 321 6848008
            </span>
          </p>
          <div className={"reactusLocation"}>
            <p>
              <img src={Location} alt="locationAvatar" />
            </p>
            <span
              style={{
                paddingLeft: "12px",
              }}
            >
              Technology Incubation Center, NUST H-12, Islamabad, Pakistan
            </span>
          </div>
        </Grid>

        <FooterEnd />
      </Grid>
    </div>
  );
}
