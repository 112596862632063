import { Grid } from "@mui/material";
import React from "react";
import "../../styles/portfolio.css";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";
import PropTypes from "prop-types";

export default function ProjectFigure({ filterProject }) {
  return (
    <Grid container className={`${"ProjectFigurecontainer"} Webcontainer`}>
      {filterProject?.projectType === "Web_Development" ? (
        <Grid item xs={12} sm={12} md={6} className={"projectImgs"}>
          <img
            src={
              filterProject &&
              filterProject?.PrimaryScreenShorts &&
              filterProject?.PrimaryScreenShorts[0]
            }
            alt="PrimaryAvatar"
            style={{
              width: "100%",
              objectFit:
                filterProject?.projectType === "Web_Development"
                  ? "cover"
                  : "scale-down",
            }}
          />
        </Grid>
      ) : (
        <Grid item xs={12} sm={12} md={12} lg={6} className={"projectImgs"}>
          {filterProject &&
            filterProject?.PrimaryScreenShorts &&
            filterProject?.PrimaryScreenShorts?.map((e, i) => (
              <img
                key={i}
                src={e}
                alt="PrimaryAvatar"
                style={{
                  objectFit:
                    filterProject?.projectType === "Web_Development"
                      ? "cover"
                      : "scale-down",
                  margin: "1rem",
                }}
              />
            ))}
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <div className={"projectFigure"}>
          <p>Project in figures</p>
          <div className={"ProjectData"}>
            <div className={"calendarDuration"}>
              <CalendarTodayOutlinedIcon
                sx={{
                  color: "#F24E1E",
                }}
              />
              <span className={"DurationNumber"}>
                {filterProject?.ProjectFigure?.DurationWorked}
              </span>
              <div className={"monthRequired"}>
                <span>Months</span>
                <p>Duration</p>
              </div>
            </div>
            <div className={"calendarDuration"}>
              <SupervisorAccountOutlinedIcon
                sx={{
                  color: "#F24E1E",
                }}
              />
              <span className={"DurationNumber"}>
                {filterProject?.ProjectFigure?.teamMembers}
              </span>
              <div className={"monthRequired"}>
                <span>Team</span>
                <p>Members</p>
              </div>
            </div>
            <div className={"calendarDuration"}>
              <QueryBuilderOutlinedIcon
                sx={{
                  color: "#F24E1E",
                }}
              />
              <span className={"DurationNumber"}>
                {filterProject?.ProjectFigure?.ManHours}
              </span>
              <div className={"monthRequired"}>
                <span>Estimated</span>
                <p>man-hours</p>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

ProjectFigure.propTypes = {
  filterProject: PropTypes.object.isRequired,
};
