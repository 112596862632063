import { CircularProgress, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import "../../styles/webPortfolio.css";
import { useDispatch, useSelector } from "react-redux";
// import DisplayProjects from "./DisplayProjects";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import { fetchInitialData } from "../../api/jobs";

export default function WebPortfolio({ projecttitle }) {
  const dispatch = useDispatch();
  const { portfolio } = useSelector((state) => state?.Portfolio);
  const activePortfolio = portfolio.filter((e) => e?.status === "active");
  const [projectsDetail, setProjectsDetail] = useState(activePortfolio);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (projectsDetail?.length > 0) return;
    fetchInitialData(dispatch, loading, setLoading, setProjectsDetail);
  }, [projectsDetail]);
  const [caurosalindex, setCaurosalIndex] = useState(0);
  return (
    <>
      {portfolio.length > 0 ? (
        <div
          id="carouselExampleControls"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            {projectsDetail.length ? (
              projectsDetail
                ?.filter((e) => String(e?.projectType) === String(projecttitle))
                .map((e, i) => (
                  <div
                    key={e?._id}
                    className={`carousel-item ${
                      caurosalindex == i ? "active" : ""
                    }`}
                  >
                    <Grid container className={"projectContainer"}>
                      <>
                        {e?.projectType === "App_Development" ? (
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div className={"laptopImage"}>
                              <img
                                key={i}
                                src={e?.PrimaryScreenShorts[0]}
                                alt="laptopAvatar"
                                style={{
                                  width: "unset",
                                  objectFit: "scale-down",
                                  margin: "0 auto",
                                }}
                                // style={{
                                //   width: "100%",
                                //   objectFit:
                                //     e?.projectType === "Web development"
                                //       ? "cover"
                                //       : "contain",
                                // }}
                              />
                            </div>
                          </Grid>
                        ) : (
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div className={"laptopImage"}>
                              <img
                                key={i}
                                src={e?.PrimaryScreenShorts[0]}
                                alt="laptopAvatar"
                                style={{
                                  width: "100%",
                                  objectFit: "contain",
                                }}
                                // style={{
                                //   width: "100%",
                                //   objectFit:
                                //     e?.projectType === "Web development"
                                //       ? "cover"
                                //       : "contain",
                                // }}
                              />
                            </div>
                          </Grid>
                        )}
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <div className={"nameAndDescription"}>
                            <p>{e.projectTitle}</p>
                            <span>{e.ShortDescription}</span>
                          </div>
                          <div className={"Languages"}>
                            {e?.TechnologyUsed.map((e, i) => (
                              <p key={i}>{e?.name}</p>
                            ))}
                          </div>
                          <Grid item xs={12} className={"learnMoreBtn"}>
                            <Link to={`/hactric/portfolio/${e?._id}`}>
                              Learn More
                              <ArrowForwardIcon
                                fontSize="small"
                                sx={{
                                  margin: "-5px 5px",
                                }}
                              />
                            </Link>
                          </Grid>
                        </Grid>
                      </>
                    </Grid>
                  </div>
                ))
            ) : (
              <p style={{ color: "white" }}>Record not found</p>
            )}
          </div>
          <a
            className="carousel-control-prev"
            href="#carouselExampleControls"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselExampleControls"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      ) : (
        <CircularProgress />
      )}
    </>
  );
}

WebPortfolio.propTypes = {
  // projectListing: PropTypes.array.isRequired,
  projecttitle: PropTypes.string.isRequired,
};
