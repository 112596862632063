import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import ApplicationStatusReducer from "./redux/reducers/ApplicationStatus";
import CurrentOpeningReducers from "./redux/reducers/CurrentOpening";
import PortfolioReducer from "./redux/reducers/PortfolioReducer";
import QuoteAutomationReducer from "./redux/reducers/QuoteAutomation";
const reducer = combineReducers({
  applicationStatusReducer: ApplicationStatusReducer,
  CurrentJobs: CurrentOpeningReducers,
  QuoteAutomation: QuoteAutomationReducer,
  Portfolio: PortfolioReducer,
});
const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, reducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
