const initialState = { CurrentJobs: [] };

export default function CurrentOpeningReducers(state = initialState, action) {
  switch (action.type) {
    case "GET_CurrentOpening":
      return { ...state, CurrentJobs: action.payload };

    default:
      return state;
  }
}
