import React from "react";
import PropTypes from "prop-types";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";

const Layout = ({ children }) => {
  return (
    <div>
      <Header />
      {children}

      <Footer />
    </div>
  );
};

Layout.propTypes = {};

export default Layout;
