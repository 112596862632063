import { Box, Grid } from "@mui/material";
import React from "react";
import "../../styles/aboutUs.css";
import TitleInfo from "../common/TitleInfo";
import ServiceVector2 from "../services/ServiceVector2";
import ValuesTag from "../valuesTag/ValuesTag";
export default function MainPage() {
  return (
    <Grid container>
      <div className={"aboutUsContainer"}>
        <TitleInfo
          title="About Hactric"
          info="We are a digital solutions providing company with expertise in Web Development, Mobile App Development and HR Recruitment. A service-based company which aims to medicate daily life problems by providing innovative solutions."
        />
      </div>
      <Box
        sx={{
          position: "relative",
          top: {xs:"0",sm:"0",md:"-190px"},
          width: "100%"
        }}
      >
        <ServiceVector2 />
      </Box>

      <ValuesTag />
    </Grid>
  );
}
