import React from "react";
import JobDetailMainPage from "../components/main/JobDetailMainPage";
import Layout from "../layout/Layout";

export default function JobDetail() {
  return (
    <Layout>
      <JobDetailMainPage />
    </Layout>
  );
}
