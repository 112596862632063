import React from "react";
import "../../styles/carrier.css";
import { Grid } from "@mui/material";
import { Link, animateScroll as scroll } from "react-scroll";
import { useNavigate } from "react-router-dom";
import TitleInfo from "../common/TitleInfo";
export default function CareerMainPage({ handleClick }) {
  const navigate = useNavigate();
  const changeRoute = () => {
    navigate("/hactric/ApplicationStatus");
  };
  return (
    <div className={`${"careerContainer"}`}>
      <TitleInfo
        title="Careers"
        info="We have some excellent openings for aspiring individuals who think that they have got the potential and skills that can be combined to bombard the ongoing revolution in the IT industry and across the digital marketing sector."
      />
      <Grid container className="Webcontainer">
        <Grid item xs={12} className={"btnHeader"}>
          <Link
            activeClass="active"
            to="myDivId"
            spy={true}
            href=""
            smooth={true}
            offset={-70}
            duration={500}
            onClick={handleClick}
            className={"currentOpeningBtn"}
          >
            View Current Openings
          </Link>
          <Link
            to=""
            onClick={changeRoute}
            href=""
            className={"applicationStatus"}
          >
            Check application status
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}
