import * as React from "react";
import { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import { Grid } from "@mui/material";
import styles from "../../styles/services.css";
import webSol1 from "../../assets/servicePageImages/webDevelopment/webSol1.png";
import WebSol2 from "../../assets/servicePageImages/webDevelopment/WebSol2.png";
import WebSol3 from "../../assets/servicePageImages/webDevelopment/WebSol3.png";
import WebSol4 from "../../assets/servicePageImages/webDevelopment/WebSol4.png";
import WebSol5 from "../../assets/servicePageImages/webDevelopment/WebSol5.png";
import WebSol6 from "../../assets/servicePageImages/webDevelopment/webSol6.png";
import dev1 from "../../assets/servicePageImages/dev1.png";
import dev2 from "../../assets/servicePageImages/dev2.png";
import dev3 from "../../assets/servicePageImages/dev3.png";
import dev4 from "../../assets/servicePageImages/dev4.png";
import dev5 from "../../assets/servicePageImages/dev5.png";
import dev6 from "../../assets/servicePageImages/dev6.png";
import frontdev from "../../assets/servicePageImages/frontdev.png";
import backdev from "../../assets/servicePageImages/backdev.png";
import Mask2 from "../mask/Mask2";
import TechImg from "../tech/TechImg";
export default function WedDevelopmentServices() {
  const [width, setWidth] = useState();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      paritialVisibilityGutter: 60,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 50,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1.5,
      paritialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 30,
    },
  };

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);

    return () => window.removeEventListener("resize", updateDimensions);
  }, [width]);

  return (
    <>
      <Grid
        container
        className={`${"WedDevelopmentServices"} Webcontainer`}
        sx={{ marginTop: { xs: "0", sm: "0", md: "-350px" } }}
      >
        <Mask2
          TagName="Development solutions designed for every business"
          xsGrid={12}
          smGrid={12}
          mdGrid={12}
          TagInfo=""
        />
        <Grid item xs={12} sm={12} className={"devlopmentTag"}></Grid>
        <Grid item xs={12} sm={4} md={3} sx={{ m: "1rem 1rem" }}>
          <TechImg
            altName="techAvatar"
            languageImg={dev1}
            languageName="Enterprise Solutions"
            isBold={false}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3} sx={{ m: "1rem 1rem" }}>
          <TechImg
            altName="techAvatar"
            languageImg={dev2}
            languageName="E-Commerce"
            isBold={false}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3} sx={{ m: "1rem 1rem" }}>
          <TechImg
            altName="techAvatar"
            languageImg={dev3}
            languageName="Progressive Web Apps"
            isBold={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ m: "1rem 1rem" }}>
          <TechImg
            altName="techAvatar"
            languageImg={frontdev}
            languageName="Front-end Development"
            isBold={false}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{ m: "1rem 1rem", fontSize: "24px" }}
        >
          <TechImg
            altName="techAvatar"
            languageImg={backdev}
            languageName="Back-end Development"
            isBold={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ m: "1rem 1rem" }}>
          <TechImg
            altName="techAvatar"
            languageImg={dev6}
            languageName="Support"
            isBold={false}
          />
        </Grid>
      </Grid>
      <div className={"ServiceWebScroll"}>
        <Grid container className="Webcontainer">
          <Grid item xs={12} sm={12} className={"devlopmentTag"}>
            <Mask2
              TagName="Development solutions designed for every business"
              xsGrid={12}
              smGrid={12}
              mdGrid={12}
              TagInfo=""
            />
          </Grid>
        </Grid>
        <div className={"ServiceScrollView"}>
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            partialVisbile={false}
            responsive={responsive}
            ssr={false} // means to render carousel on server-side.
            infinite={true}
            autoPlaySpeed={2000}
            autoPlay={true}
            keyBoardControl={true}
            containerClass="carousel-container"
            removeArrowOnDeviceType={[
              "tablet",
              "mobile",
              "desktop",
              "superLargeDesktop",
            ]}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            <div className={"webServicesScrollView"}>
              <TechImg
                altName="techAvatar"
                languageImg={dev1}
                languageName="Enterprise Solutions"
                isBold={false}
              />
            </div>
            <div className={"webServicesScrollView"}>
              <TechImg
                altName="techAvatar"
                languageImg={dev2}
                languageName="Enterprise Solutions"
                isBold={false}
              />
            </div>
            <div className={"webServicesScrollView"}>
              <TechImg
                altName="techAvatar"
                languageImg={dev3}
                languageName="Progressive Web Apps"
                isBold={false}
              />
            </div>
            <div className={"webServicesScrollView"}>
              <TechImg
                altName="techAvatar"
                languageImg={frontdev}
                languageName="Front-end Development"
                isBold={false}
              />
            </div>
            <div className={"webServicesScrollView"}>
              <TechImg
                altName="techAvatar"
                languageImg={backdev}
                languageName="Back-end Development"
                isBold={false}
              />
            </div>
            <div className={"webServicesScrollView"}>
              <TechImg
                altName="techAvatar"
                languageImg={dev6}
                languageName="Support"
                isBold={false}
              />
            </div>
          </Carousel>
        </div>
      </div>
    </>
  );
}
