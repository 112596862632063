import React from "react";
import { Grid, Card } from "@mui/material";
import "../../styles/carrier.css";
export default function JoinUsTags({ JoinUsTagImage, JoinUsTitle }) {
  return (
    <Card className={"JoinUsContainer"}>
      <div className={"JoinUsTagImage"}>
        <img src={JoinUsTagImage} alt="AvatarTech" />
      </div>
      <div className={"JoinUsTitle"}>
        <p>{JoinUsTitle}</p>
      </div>
    </Card>
  );
}
