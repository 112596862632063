import { Button, CircularProgress, Grid } from "@mui/material";
import React, { useState } from "react";
import "../../styles/applicationStatus.css";
import thanksEmoji from "../../assets/thanksEmoji.png";
import tickVector from "../../assets/tickVector.png";
import cross from "../../assets/cross.png";
import { toast, ToastContainer } from "react-toastify";
// import format from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import api from "../../api/request";

export default function ApplicationStatusMainPage() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { applicationStatus } = useSelector(
    (state) => state?.applicationStatusReducer
  );
  const [trackingInput, setTrackingInput] = useState();
  const [visible, setVisible] = useState(false);

  const CheckTheStatus = async () => {
    try {
      if (!trackingInput) {
        toast.error("Enter a Tracking Number");
        return;
      }
      setLoading(true);
      const resp = await api.get(`/api/candidate/${trackingInput}`);

      toast.success(resp?.data?.message);
      dispatch({
        type: "GET_ALL_APPLICATIONS",
        payload: resp?.data?.data,
      });
      setVisible(true);
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message || error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Grid
        container
        sx={{
          paddingTop: { xs: "0", sm: "0", md: "8rem" },
          display: "flex",
          alignItems: "center",
          transform: {
            xs: "translateY(90px)",
            sm: "translateY(90px)",
            md: "translateY(0)",
          },
          height: { xs: "100vh", sm: "100vh", md: "100vh", lg: "100vh" },
        }}
        className="Webcontainer"
      >
        <Grid item xs={12} sm={12}>
          <p className={"pageTitle"}> Check your job application status</p>
          <p className={"PageInfo"}>
            Enter your job application tracking number which was shown to you
            when you applied for the job and also sent to the email you provided
            us.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} className={"trackingInput"}>
          <input
            placeholder="Tracking Number i.e. tr127394"
            onChange={(e) => setTrackingInput(e.target.value)}
          />
          <div className={"tackingCheckingBtn"}>
            <Button onClick={CheckTheStatus}>Check</Button>
          </div>
          <ToastContainer />
        </Grid>
        {loading ? (
          <div
            style={{
              marginTop: "1rem",
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          ""
        )}
        {visible ? (
          <>
            <Grid item xs={12} sm={6} md={4} className={"trackingInfo"}>
              <span>Tracking number:</span>
              <span>{applicationStatus?.trackingNumber}</span>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={"trackingInfo"}>
              <span>Job Applied For:</span>
              <span>{applicationStatus?.jobId?.jobTitle} </span>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={"trackingInfo"}>
              <span>Applied on :</span>
              <span>
                {new Date(applicationStatus?.createdAt).toLocaleDateString()}
              </span>
            </Grid>
            <Grid item xs={12} className={"trackingStatus"}>
              {applicationStatus?.status?.map((e) =>
                e?.title === "Shortlist Candidate" && e?.status === "Failed" ? (
                  <span
                    style={{
                      fontSize: "1rem",
                    }}
                  >
                    Status: Unfortunately you weren’t able to Shorlist
                  </span>
                ) : e?.title === "HR Screening" && e?.status === "Passed" ? (
                  <>
                    <p
                      style={{
                        fontSize: "1rem",
                        // lineHeigh",
                        color: "white",
                      }}
                    >
                      Status:
                    </p>
                    <span
                      style={{
                        fontSize: "1rem",
                        color: "white",
                      }}
                    >
                      HR Screening on{" "}
                      {/* {format(new Date(e?.date), "do/MM/yyyy hh:mm a")} */}
                      {new Date(e?.date).toLocaleString()}
                    </span>
                  </>
                ) : e?.title === "HR Screening" && e?.status === "Failed" ? (
                  <span
                    style={{
                      fontSize: "1rem",
                    }}
                  >
                    Status: Unfortunately you weren’t able to pass our HR
                    Screening.
                  </span>
                ) : (
                  ""
                )
              )}
            </Grid>
            <Grid item xs={12} className={"trackingStatus"}>
              {applicationStatus?.status?.map((e) =>
                e?.title === "Final interview" && e?.status === "Passed" ? (
                  <>
                    <span>
                      Final interview on{" "}
                      {/* {format(new Date(e?.date), "do/MM/yyyy hh:mm a")} */}
                      {new Date(e?.date).toLocaleString()}
                    </span>
                  </>
                ) : e?.title === "Final interview" && e?.status === "Failed" ? (
                  <span>
                    Status: Unfortunately you weren’t able to pass our Final
                    Interview.
                  </span>
                ) : (
                  ""
                )
              )}
            </Grid>
          </>
        ) : (
          ""
        )}
      </Grid>

      {visible ? (
        <>
          <div className={"stepper"}>
            <div className={"wrapper"}>
              {applicationStatus &&
              applicationStatus?.status &&
              applicationStatus?.status[0].status === "Passed" ? (
                <img src={tickVector} alt="tickVectorAvatar" />
              ) : applicationStatus &&
                applicationStatus?.status &&
                applicationStatus?.status[0].status === "Failed" ? (
                <img src={cross} alt="crossVectorAvatar" />
              ) : (
                ""
              )}

              <div
                className={
                  applicationStatus &&
                  applicationStatus?.status &&
                  applicationStatus?.status[0].status === "Passed"
                    ? `${"step"} ${"active"}`
                    : applicationStatus &&
                      applicationStatus?.status &&
                      applicationStatus?.status[0].status === "Failed"
                    ? ` ${"step"} ${"Failed"}`
                    : `${"step"}`
                }
                //
                // e?.title === "Application Status" && e?.status === "Passed"
              ></div>
              <h5>Application Received</h5>
            </div>
            <div className={"wrapper"}>
              {applicationStatus &&
              applicationStatus?.status &&
              applicationStatus?.status[1].status === "Passed" ? (
                <img src={tickVector} alt="tickVectorAvatar" />
              ) : applicationStatus &&
                applicationStatus?.status &&
                applicationStatus?.status[1].status === "Failed" ? (
                <img src={cross} alt="crossVectorAvatar" />
              ) : (
                ""
              )}
              <div
                className={
                  applicationStatus &&
                  applicationStatus?.status &&
                  applicationStatus?.status[1].status === "Passed"
                    ? `${"step"} ${"active"}`
                    : applicationStatus &&
                      applicationStatus?.status &&
                      applicationStatus?.status[1].status === "Failed"
                    ? ` ${"step"} ${"Failed"}`
                    : `${"step"}`
                }
              ></div>
              <h5>Shortlisted for Interview</h5>
            </div>
            <div className={"wrapper"}>
              {applicationStatus &&
              applicationStatus?.status &&
              applicationStatus?.status[2].status === "Passed" ? (
                <img src={tickVector} alt="tickVectorAvatar" />
              ) : applicationStatus &&
                applicationStatus?.status &&
                applicationStatus?.status[2].status === "Failed" ? (
                <img src={cross} alt="crossAvatar" />
              ) : (
                ""
              )}
              <div
                className={
                  applicationStatus &&
                  applicationStatus?.status &&
                  applicationStatus?.status[2].status === "Passed"
                    ? `${"step"} ${"active"}`
                    : applicationStatus &&
                      applicationStatus?.status &&
                      applicationStatus?.status[2].status === "Failed"
                    ? ` ${"step"} ${"Failed"}`
                    : `${"step"}`
                }
              ></div>
              <h5>HR Screening</h5>
            </div>
            <div className={"wrapper"}>
              {applicationStatus &&
              applicationStatus?.status &&
              applicationStatus?.status[3].status === "Passed" ? (
                <img src={tickVector} alt="tickVectorAvatar" />
              ) : applicationStatus &&
                applicationStatus?.status &&
                applicationStatus?.status[3].status === "Failed" ? (
                <img src={cross} alt="crossvectorAvatar" />
              ) : (
                ""
              )}
              <div
                className={
                  applicationStatus &&
                  applicationStatus?.status &&
                  applicationStatus?.status[3].status === "Passed"
                    ? `${"step"} ${"active"}`
                    : applicationStatus &&
                      applicationStatus?.status &&
                      applicationStatus?.status[3].status === "Failed"
                    ? ` ${"step"} ${"Failed"}`
                    : `${"step"}`
                }
              ></div>
              <h5>Final Interview</h5>
            </div>
            <div className={"wrapper"}>
              {applicationStatus &&
              applicationStatus?.status &&
              applicationStatus?.status[4].status === "Passed" ? (
                <img src={tickVector} alt="tickVectorAvatar" />
              ) : applicationStatus &&
                applicationStatus?.status &&
                applicationStatus?.status[4].status === "Failed" ? (
                <img src={cross} alt="crossVectorAvatar" />
              ) : (
                ""
              )}
              <div
                className={
                  applicationStatus &&
                  applicationStatus?.status &&
                  applicationStatus?.status[4].status === "Passed"
                    ? `${"step"} ${"active"}`
                    : applicationStatus &&
                      applicationStatus?.status &&
                      applicationStatus?.status[4].status === "Failed"
                    ? ` ${"step"} ${"Failed"}`
                    : `${"step"}`
                }
              ></div>
              <h5>Hired</h5>
            </div>
          </div>

          <div className={"thanksmessage"}>
            <span>Thanks for applying at Hactric. We wish you good luck.</span>
            <img src={thanksEmoji} alt="thanksvectorAvatar" />
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
