import React from "react";
import { Grid, Button, Card, Box } from "@mui/material";
import "../../styles/homeService.scss";
import ServiceVector from "../../assets/ServiceVector.png";
import service1 from "../../assets/service1.png";
import service2 from "../../assets/service2.png";
import service3 from "../../assets/service3.png";
import IOS from "../../assets/icons/IOS.svg";
import android from "../../assets/icons/Android.svg";
import react from "../../assets/icons/React.svg";
import Firebase from "../../assets/icons/Firebase.svg";
import HTML5 from "../../assets/icons/HTML5.svg";
import Nodejs from "../../assets/icons/Nodejs.svg";
import MongoDB from "../../assets/icons/MongoDB.svg";
import swift from "../../assets/icons/Swift.svg";
import Kotlin from "../../assets/icons/Kotlin.svg";
import Mask from "../mask/Mask";
import WebButton from "../webButton/WebButton";
import SmallImageHandler from "../smallImageHandler/SmallImageHandler";
import TechnologiesToWork from "../tech/TechnologiesToWork";
import PartnerShip from "../partner/PartnerShip";
import TalkWithUs from "../talk/TalkWithUs";
import OurClientSpeaks from "../clients/OurClientSpeaks";

export default function Services() {
  return (
    <>
      <Mask
        TagName="Our Services"
        xsGrid={12}
        smGrid={12}
        mdGrid={12}
        TagInfo=""
      />
      <Grid
        container
        sx={
          {
            // mt: "-16rem",
          }
        }
      >
        <Grid item xs={12} className={"ServiceVector"}>
          <img src={ServiceVector} alt="VectorAvatar" />
        </Grid>

        <Card
          className={"Service1"}
          sx={{
            margin: "0 auto 2rem",
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={12} md={7}>
              <div className={"service1Title"}>
                <p>App</p>
                <p>Development</p>
              </div>
              <div className={"service1info"}>
                Our profound knowledge of mobile technologies & frameworks, help
                us create secure & scalable mobile apps with great UX.
              </div>
              <div className={"languageImg"}>
                <SmallImageHandler
                  LinksTrue={false}
                  ImageLinks=""
                  LinkImage={IOS}
                />
                <SmallImageHandler
                  LinksTrue={false}
                  ImageLinks=""
                  LinkImage={android}
                />
                <SmallImageHandler
                  LinksTrue={false}
                  ImageLinks=""
                  LinkImage={react}
                />
                <SmallImageHandler
                  LinksTrue={false}
                  ImageLinks=""
                  LinkImage={swift}
                />
                <SmallImageHandler
                  LinksTrue={false}
                  ImageLinks=""
                  LinkImage={Kotlin}
                />
              </div>

              <WebButton
                ArrowStatus={true}
                LinkURl="/hactric/appdevelopment"
                LinkTitle="View Service"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} className={"service1img"}>
              <img src={service1} alt="serviceVector" />
            </Grid>
          </Grid>
        </Card>
        <Card
          className={`${"Service1"}`}
          sx={{
            margin: "0 auto 2rem",
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={12} md={6} className={"service2img"}>
              <img src={service2} alt="serviceVector" />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div className={"service1Title"}>
                <p>Web</p>
                <p>Development</p>
              </div>
              <div className={"service1info"}>
                Technology expertise coupled with great UX skills is what helps
                us build world class websites and web applications.
              </div>
              <div className={"languageImg"}>
                <SmallImageHandler
                  LinksTrue={false}
                  ImageLinks=""
                  LinkImage={Nodejs}
                />
                <SmallImageHandler
                  LinksTrue={false}
                  ImageLinks=""
                  LinkImage={react}
                />
                <SmallImageHandler
                  LinksTrue={false}
                  ImageLinks=""
                  LinkImage={Firebase}
                />
                <SmallImageHandler
                  LinksTrue={false}
                  ImageLinks=""
                  LinkImage={MongoDB}
                />
                <SmallImageHandler
                  LinksTrue={false}
                  ImageLinks=""
                  LinkImage={HTML5}
                />
              </div>

              <WebButton
                ArrowStatus={true}
                LinkURl="/hactric/webdevelopment"
                LinkTitle="View Service"
              />
            </Grid>
          </Grid>
        </Card>
        {/* <Card
          className={`${"Service1"}`}
          sx={{
            margin: "0 auto 2rem",
          }}
        >
          {" "}
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <div className={"service1Title"}>
                <p>HR</p>
                <p>Recruitment</p>
              </div>
              <div className={"service1info"}>
                Within 48 hours, we’ll set up a team of highly talented software
                engineers which will be the perfect fit for your company.
              </div>

              <WebButton
                ArrowStatus={true}
                LinkURl="http://hactric-hr.s3-website.ap-south-1.amazonaws.com"
                LinkTitle="View Service"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} className={"service1img"}>
              <img src={service3} alt="serviceAvatar" />
            </Grid>
          </Grid>
        </Card> */}
      </Grid>

      <Box sx={{ margin: { xs: "2rem 0", sm: "2rem 0", md: "7rem 0 0 0" } }}>
        <TechnologiesToWork />
      </Box>
      <div style={{ margin: "2rem 0" }}>
        <PartnerShip />
      </div>

      {/* <div>
        <OurClientSpeaks />
      </div> */}
      <div>
        <TalkWithUs />
      </div>
    </>
  );
}
