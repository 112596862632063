import React from "react";
import { Grid, Paper } from "@mui/material";
import "../../styles/aboutUs.css";
import aboutUs from "../../assets/aboutUS/AboutUs.png";
import aboutUs2 from "../../assets/aboutUS/AboutUs2.png";
import aboutUs3 from "../../assets/aboutUS/AboutUs3.png";
import ServiceVector from "../../assets/ServiceVector.png";
import Mask2 from "../mask/Mask2";

export default function ValuesTag() {
  return (
    <Grid
      container
      sx={{
        marginTop: { xs: "0", sm: "-370px", md: "-490px" },
      }}
    >
      <Mask2
        TagName="Our Values"
        xsGrid={12}
        smGrid={12}
        mdGrid={12}
        TagInfo=""
      />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{
          // marginTop: "-10rem",
          display: {
            xs: "none",
            sm: "block",
            position: "absolute",
            // top: "-10px",
          },
        }}
      >
        <img
          src={ServiceVector}
          alt="ServiceVectorAvatar"
          width={200}
          height={120}
          loading="lazy"
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: { xs: "0rem", sm: "2rem" },
        }}
      >
        <Paper elevation={3} className={"AboutUsCard"}>
          <div
            style={{
              width: "100%",
            }}
          >
            <img
              src={aboutUs}
              alt="AboutUsAvatar"
              width={45}
              height={45}
              loading="lazy"
            />
          </div>
          <p>Strong Relationships </p>
          <p>
            {" "}
            We don’t believe in building a client roster, but a group of
            partners invested in the other’s success.
          </p>
        </Paper>
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: "2rem",
        }}
      >
        <Paper elevation={3} className={"AboutUsCard"}>
          <img
            src={aboutUs2}
            alt="aboutUsAvatar"
            width={45}
            height={45}
            loading="lazy"
          />
          <p>Excellence at work </p>
          <p>
            We will to go the extra mile, the desire to be better than we were
            yesterday and the instinct to win – all boil down to one thing:
            Excellence.
          </p>
        </Paper>
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: "2rem",
        }}
      >
        <Paper elevation={3} className={"AboutUsCard"}>
          <img
            src={aboutUs3}
            alt="AboutUsAvatar"
            width={45}
            height={45}
            loading="lazy"
          />
          <p>Innovation & Creativity </p>
          <p>
            The tenacity to consistently think out of the box, even when it
            borders on bizarre, is how the world changes.
          </p>
        </Paper>
      </Grid>
    </Grid>
  );
}
