import { toast } from "react-toastify";

export const getErrorMessage = (error) =>
  toast.error(error?.response?.data?.message || error?.message || error);

export const projects = [
  {
    title: "Web_Development",
    completed: [
      "Real Estate",
      "Logistics & Transportation",
      "Information Technology",
      "Business Services",
      "eCommerce",
      "Entertainment",
      "Entertainment",
      "Entertainment",
    ],
  },
  {
    title: "App_Development",
    completed: [
      "Logistics & Transportation",
      "Real Estate",
      "Information Technology",
      "Business Services",
      "eCommerce",
      "Entertainment",
    ],
  },
];
