import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import { useDispatch, useSelector } from "react-redux";
import { PlateFormSelection } from "../quote/PlateFormSelection";
import { handlerQuotes } from "../../api/jobs";

export default function QuoteAutomationMainPage() {
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const { QuoteAutomation } = useSelector((state) => state?.QuoteAutomation);

  useEffect(() => {
    handlerQuotes(dispatch);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(value + 1);
    if (value >= 9) {
      setValue(1);
    }
  };
  return (
    <>
      <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
        <Slider
          min={0}
          max={QuoteAutomation?.length}
          step={1}
          aria-label="Volume"
          value={value}
          // onChange={handleChange}
          valueLabelDisplay="on"
        />

        {/* <p>{value + "/ " + 9}</p> */}
      </Stack>
      <PlateFormSelection
        handleChange={handleChange}
        value={value}
        setValue={setValue}
      />
    </>
  );
}
