import React from "react";
import QuoteAutomationMainPage from "../components/main/QuoteAutomationMainPage";
import Layout from "../layout/Layout";
export default function QuoteAutomation() {
  return (
    <Layout>
      <div className="Webcontainer">
        <QuoteAutomationMainPage />
      </div>
    </Layout>
  );
}
