import { Box } from "@mui/material";
import React from "react";
import CustomerConenct from "../components/customer/CustomerConnect";
import MainPage from "../components/main/MainPage";
import OurStory from "../components/story/OurStory";
import TalkWithUs from "../components/talk/TalkWithUs";
import Layout from "../layout/Layout";
export default function AboutUs() {
  return (
    <Layout>
      <MainPage />
      <CustomerConenct />
      <Box
        sx={{
          marginTop: { xs: 0, sm: 0, md: "16rem" },
        }}
      >
        <OurStory />
      </Box>
      <div className="aboutUsTlakWithUs">
        <TalkWithUs />
      </div>
    </Layout>
  );
}
