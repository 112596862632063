import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import "../../styles/portfolio.css";
import "../../styles/singlePortfolio.css";

import { useDispatch, useSelector } from "react-redux";
// import { fetchInitialData } from "../../AsyncFunctions";
import { useNavigate, useParams } from "react-router-dom";
import ProjectFigure from "../projects/ProjectFigure";
import Challenges from "../challenges/Challenges";
import Solutions from "../solution/Solutions";
import TechnologyStack from "../tech/TechnologyStack";
import TalkWithUs from "../talk/TalkWithUs";
import { fetchInitialData } from "../../api/jobs";
import Layout from "../../layout/Layout";
export default function PortfolioDetailMainPage() {
  const { projectDetail } = useParams();
  console.log(projectDetail);
  const [filterProject, setFilterProject] = useState({});
  const { portfolio } = useSelector((state) => state?.Portfolio);

  const dispatch = useDispatch();

  useEffect(() => {
    const filterProject =
      portfolio && portfolio?.find((e) => e?._id === projectDetail);
    setFilterProject(filterProject);
    fetchInitialData(dispatch);
  }, []);

  return (
    <Layout>
      <div className={"CoverImage"}>
        {filterProject && filterProject?.coverImage && (
          <img
            src={filterProject && filterProject?.coverImage}
            style={{
              objectFit: "cover",
              width: "100% !important",
              height: "80vh !important",
            }}
            alt="coverAvatar"
          />
        )}
        <div className={"overLay"}></div>
      </div>
      <div className={"coverNameAndImage"}>
        <div className={"projectNameAndDescription"}>
          <p>{filterProject?.projectTitle}</p>
          <span>{filterProject?.ShortDescription}</span>
        </div>
        <div className={"coverImg"}>
          {filterProject && filterProject?.PrimaryScreenShorts && (
            <img
              src={
                filterProject &&
                filterProject?.PrimaryScreenShorts &&
                filterProject?.PrimaryScreenShorts[0]
              }
              width={150}
              height={450}
              style={{
                width: "100%",
                objectFit: "contain",
              }}
              alt="mobile Avatar"
            />
          )}
          {/* <img src={Home} alt="mobile Avatar" className={"smartphone"} /> */}
        </div>
      </div>
      <Grid
        container
        className={`${"aboutUSContainer"} Webcontainer mt-5 mb-5`}
      >
        <Grid item xs={12} sm={12} md={6} className={"AboutUsProject"}>
          <p>About</p>
          <span>{filterProject?.DetailDescription}</span>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={"expertiseAndDelivered"}>
          <p>Expertise Delivered</p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            {filterProject?.TechnologyUsed?.map((e, i) => (
              <span
                key={i}
                style={{
                  marginTop: "0.5rem",
                }}
              >
                {e?.name}
              </span>
            ))}
          </div>
          <p>Industry</p>
          <div
            style={{
              margin: "1rem 0",
            }}
          >
            <span>{filterProject?.industry}</span>
          </div>
        </Grid>
      </Grid>
      <ProjectFigure filterProject={filterProject} />
      <Challenges filterProject={filterProject} />
      <Solutions filterProject={filterProject} />
      <TechnologyStack filterProject={filterProject} />
      <div
        style={{
          marginBottom: "-121px",
        }}
      >
        <TalkWithUs filterProject={filterProject} />
      </div>
    </Layout>
  );
}
